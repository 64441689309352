import { useContext, useMemo, useState } from 'react'
import { TextField, Grid, Box, Button, Divider, Typography } from '@mui/material'
import { multiStepContext } from '../../../../../contexts/VacancyContext'
import { initValue, isValidMinMaxText,helperValidMinMaxText } from '../../../../../util/Index'
import { useFormik } from 'formik'
import * as Yup from 'yup'

export const Step3 = () => {
	const { setStep, userData, setUserData, shouldDisable } = useContext(multiStepContext)
	const [wordCount, setWordCount] = useState(0)

	class validationForm {
		Describa_vacante: string = initValue(userData['Describa_vacante']);
		perfil_oculto: string = initValue(userData['perfil_oculto']);
	}

	const formSchema = useMemo(
		() =>
			Yup.object().shape({
				Describa_vacante: Yup.string()
					.required('Campo requerido')
					.min(200, 'Descripción muy corta, mínimo 200 caracteres')
					.max(2000, 'Descripción muy extensa, máximo 2000 caracteres'),
				perfil_oculto: Yup.string()
					.nullable()
					.min(50, 'Descripción muy corta, mínimo 50 caracteres')
					.max(200, 'Descripción muy extensa, máximo 200 caracteres'),
			}),
		[]
	)

	const formik = useFormik({
		initialValues: new validationForm(),
		validationSchema: formSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			try {
				setSubmitting(true)
			} catch (error) {
				setSubmitting(false)
				// setStatus('Informaçoes incorretas')
			}
		},
	})

	return (
		<>
			<Box sx={{ mb: 3 }}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					Describa los detalles de la vacante
				</Typography>
			</Box>
			<Grid container direction='row' justifyContent='space-between' width={'100%'} item={true}>
				<Grid xs={12} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						{...formik.getFieldProps('Describa_vacante')}
						required 
						label='Descripción'
						placeholder='Describa los detalles de la vacante'
						onChange={(e) => {
							setUserData({ ...userData, Describa_vacante: e.target.value })
							formik.handleChange(e)
						}}
						multiline
						value={initValue(userData['Describa_vacante'])}
						minRows={7}
						maxRows={10}
						inputProps={{ minLength: 200, maxLength: 2000 }}
						error={formik.touched.Describa_vacante && !isValidMinMaxText(userData['Describa_vacante'], 200, 2000, true)}
						helperText={(helperValidMinMaxText(userData['Describa_vacante'], 200, 2000, true)!='Campo requerido'||formik.touched.Describa_vacante)?helperValidMinMaxText(userData['Describa_vacante'], 200, 2000, true):'0/2000'}
						disabled={shouldDisable}
					/>
				</Grid>
			</Grid>
			<Box sx={{ mb: 3 }}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					Perfil oculto de la vacante
				</Typography>
			</Box>
			<Grid container direction='row' justifyContent='space-between' width={'100%'} item={true}>
				<Grid xs={12} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						{...formik.getFieldProps('perfil_oculto')}						 
						label='Perfil oculto de la vacante'
						placeholder='Perfil oculto de la vacante'
						onChange={(e) => {
							setUserData({ ...userData, perfil_oculto: e.target.value })
							formik.handleChange(e)
						}}
						multiline
						value={initValue(userData['perfil_oculto'])}
						minRows={3}
						maxRows={5}
						inputProps={{ minLength: 50, maxLength: 200 }}
						error={formik.touched.perfil_oculto && !isValidMinMaxText(userData['perfil_oculto'], 50, 200, false)}
						helperText={(helperValidMinMaxText(userData['perfil_oculto'], 50, 200, false)!=''||formik.touched.perfil_oculto)?helperValidMinMaxText(userData['perfil_oculto'], 50, 200, false):'0/200'}
						disabled={shouldDisable}
					/>
				</Grid>				
			</Grid>	
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90 }}
					onClick={() => setStep(2)}
				>
					Atrás
				</Button>
				{/*<Box sx={{ flex: '1 1 auto' }} />*/}
				<Button
					variant='contained'
					sx={{ background: '#0A4396', width: 90 }}
					onClick={() => {
						formik.handleSubmit()
						if (formik.isValid) setStep(4)
					}}
				>
					Siguiente
				</Button>
			</Box>
		</>
	)
}
