import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import { DateSelectArg, EventApi, EventClickArg } from '@fullcalendar/core';
import './styles.css';
import Avatar from 'react-avatar';
import { PageTitle } from '../../../_metronic/layout/core';
import Modal from '@mui/material/Modal';
import { Alert, Autocomplete, Box, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, Typography } from '@mui/material';
import { LocalizationProvider, TimePicker, TimeValidationError } from '@mui/x-date-pickers';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
	deleteMeetApi,
	getMeetsByCandidate,
	getMeetsByCouselor,
	listConsuelorMeets,
	meetListUsersAvailables,
	storeMeetApi,
	doneMeetApi,
	listCandidateAvailableMeets,
	getUserDataById
} from '../../modules/apps/user-management/users-list/core/_requests';
import { useAuth } from '../../../app/modules/auth';
import moment from 'moment';
import { convertDateToYYYYMMDD, dateMinusNYears, addMinutes, convertMilitaryTimeTo24HourFormat, convertUTCDateToLocalDate, convertMilitaryTimeTo12HourFormat, convertMsToTime, convertTimeToMs, concatArrayToStringJoinBy, isValidMinMaxText } from '../../util/Index';
import { MeetsUserModel } from '../../models/MeetsUserModel';
import { HoursMeetsModel } from '../../models/HoursMeetsModel';
import dayjs from 'dayjs';
import BackButton from '../BackButton/BackButton';
import { LoadingBackdrop } from '../LoadingSpinner/LoadingSpinner';
import {
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	CircularProgress,
	Button,
	LinearProgress
} from '@mui/material';
import { initValue } from '../../util/Index';
import { createFilterOptions } from '@mui/material/Autocomplete';

export interface UserTargetModel {
	consejeroId: number;
	candidatoId?: string;
	candidatoNombre?: string;
	primerNombre: string;
	segundoNombre: string;
	primerApellido: string;
	segundoApellido: string;
	numeroDoc?: string;
}

const CustomCalendarComponent = () => {

	const { currentUser, isCounselorCandidateRole, isCandidateRole } = useAuth();
	const [openModalCrearCita, setOpenModalCrearCita] = useState(false);
	const [openModalInfoCita, setOpenModalInfoCita] = useState(false);
	const [eventSelected, setEevntSelected]: any = useState();
	const handleCloseModalInfoCita = () => setOpenModalInfoCita(false);
	const [daySelected, setDaySelected] = useState(new Date());
	const [timeSelected, setTimeSelected] = useState(false);
	const [dateMeet, setDateMeet] = useState<Date | null>(new Date());
	const [listUsersAvailables, setListUsersAvailables]: any[] = useState([]);
	const [usersMeetsList, setUsersMeetsList]: any[] = useState([]);
	const [targetUserScheduleList, setTargetUserScheduleList]: any[] = useState([]);
	const [userSchedule, setUserSchedule]: any = useState();
	const [eventsMeet, setEventsMeet]: any[] = useState([]);
	const [eventsMeetLoaded, setEventsMeetLoaded] = useState(false);
	const [open, setOpen] = useState(false);
	const [msgAlert, setMsgAlert] = useState('');
	const [isError, setIsError] = useState(false);
	const [currentMonthFullCalendar, setCurrentMonthFullCalendar] = useState(0);
	const [errorTimePicker, setErrorTimePicker] = useState<TimeValidationError | null>();
	const [hourSelected, setHhourSelected] = useState(0);
	const [minuteSelected, setMinuteSelected] = useState(0);
	const [requestInProgress, setRequestInProgress] = useState(false);
	const [filterUserBy, setFilterUserBy] = useState('');

	//Variable que conserva la informacion de la fecha del componente FullCalendar
	const [currentDateFullCalendar, setCurrentDateFullCalendar] = useState<Date | null>();
	const currentDateFullCalendarRef = useRef(currentDateFullCalendar);

	//El useRefHook le permite conservar valores entre renderizados. 
	//Establecemos referencia para poder leer el valor desde metodos useCallback
	const currentMonthFullCalendarRef = useRef(currentMonthFullCalendar);

	//Tiempo de duracion de una reunion en minutos
	const DURATION_PEER_MEET = 30;

	const [autoHideDuration, setAutoHideDuration] = useState(6000);
	const [habilitarHora, sethabilitarHora] = useState(false);

	const handleClose = () => {
		setOpen(false);
		setMsgAlert('');
		setIsError(false);
	};

	const [targetUserIdSelect, setTargetUserIdSelect] = useState();
	const [targetUserSelect, setTargetUserSelect] = useState();
	//Flag de procesamiento de style de eventos en componente FullCalendar
	const [eventsFullCalendarStyleInProgress, setEventsFullCalendarStyleInProgress] = useState(false);
	const [agendamientoHabilitado, setAgendamientoHabilitado]: any[] = useState(false);


	const handleCloseModalCrearCita = () => {
		if (!isCandidateRole()) {
			setFilterUserBy('');
			//Obtiene lista de consejeros disponibles. Si el usuario es consejero solo se consulta asi mismo		
			getMeetListUsersAvailables();
			//Obtiene informacion de disponibilidad, jornada laboral y meets de los usuarios objeto para agendar
			getUsuariosMeets(currentMonthFullCalendar, currentDateFullCalendar ? currentDateFullCalendar?.getFullYear() : new Date().getFullYear());
		}
		setOpenModalCrearCita(false);
	};


	useEffect(() => {
		getUserInfo();
		//Manejo de tiempos
		const today = new Date();
		const todayColombia = new Date(
			new Date(today).toLocaleString('en-US', {
				timeZone: 'America/Bogota'
			}),
		);
		let month = todayColombia.getMonth() + 1;
		let year = todayColombia.getFullYear();
		//Mapea el mes del sistema y lo establece en la variable de estado
		setCurrentMonthFullCalendar(month);
		//Obtiene lista de consejeros disponibles. Si el usuario es consejero solo se consulta asi mismo		
		getMeetListUsersAvailables();
		//Obtiene informacion de disponibilidad, jornada laboral y meets de los usuarios objeto para agendar
		getUsuariosMeets(month, year);
	}, []);


	//Listener sobre meetsLoad (Agenda del usuario), listUsersAvailables (Lista de usuarios disponibles), consuelorMeetsList (Agenda de consejeros)
	useEffect(() => {
		if (eventsMeetLoaded && listUsersAvailables && usersMeetsList) {
			processUserMeets(eventsMeet, listUsersAvailables, usersMeetsList)
		}
	}, [eventsMeetLoaded, listUsersAvailables, usersMeetsList]);

	//Listener sobre targetUserScheduleList (Agendas de usuarios procesadas con horarios disponibles y no disponibles)
	useEffect(() => {
		if (targetUserScheduleList) {
			const userSchedule = targetUserScheduleList?.length > 0 ? targetUserScheduleList[0] : usersMeetsList[0];
			setUserSchedule(userSchedule)
			setTargetUserIdSelect(userSchedule?.candidatoId || userSchedule?.consejeroId)
			if (isCandidateRole()) {
				setTargetUserSelect(userSchedule?.consejeroId)
			}
		}

	}, [targetUserScheduleList, daySelected]);


	//Listener sobre targetUserIdSelect (Usuario seleccionado)
	useEffect(() => {
		if (targetUserIdSelect) {
			const consuelorSchedule = targetUserScheduleList.find(({ consejeroId, candidatoId }: { consejeroId: number, candidatoId: number }) => (candidatoId || consejeroId) === targetUserIdSelect);
			setUserSchedule(consuelorSchedule)
		}

	}, [targetUserIdSelect]);

	//Listener sobre currentMonthFullCalendar
	useEffect(() => {
		if (currentMonthFullCalendar > 0) {
			currentMonthFullCalendarRef.current = currentMonthFullCalendar;
			//Carga informacion de meets de usuarios objetivo
			getUsuariosMeets(currentMonthFullCalendar, currentDateFullCalendar ? currentDateFullCalendar?.getFullYear() : new Date().getFullYear());
			//Obtiene la informacion de las citas del usuario autenticado
			loadEventsMeetsByUserAuthenticated()
		}
	}, [currentMonthFullCalendar]);

	//Listener sobre currentDateFullCalendar
	useEffect(() => {
		if (currentDateFullCalendar) {
			currentDateFullCalendarRef.current = currentDateFullCalendar;
		}
	}, [currentDateFullCalendar]);

	/**
	 * Actualiza el evento como logrado
	 */
	async function confirmDoneMeet() {
		if (!error && atenderCita['id'] > 0) {
			try {
				setRequestInProgress(true);
				let payload = atenderCita;
				await doneMeetApi(payload).then(() => {
					loadEventsMeetsByUserAuthenticated();
					setMsgAlert('Evento actualizado con éxito');
					setIsError(false);
					handleCloseModalAtenderCita();
					eventSelected.remove();
				});
			} catch (error: any) {
				let message = ''
				if (error.response) {
					message = error.response.data.error;
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
					message = 'Ocurrio un error procesando la solicitud.';
				}
				setMsgAlert(message);
				setIsError(true);
				setOpen(true);
			} finally {
				handleCloseModalAtenderCita();
				setRequestInProgress(false);
			}
		}
	}

	/**
	 * Registra una cita en la agenda
	 */
	async function storeMeet() {
		setRequestInProgress(true);
		handleCloseModalCrearCita();
		dateMeet?.setHours(hourSelected);
		dateMeet?.setMinutes(minuteSelected);
		let idUser = currentUser?.user?.id;
		let obj = {
			id_candidate: isCandidateRole() ? idUser : targetUserIdSelect,
			id_couselor: isCandidateRole() ? targetUserIdSelect : idUser,
			data: dateMeet?.getTime() //Returns the stored time value in milliseconds since midnight, January 1, 1970 UTC
		};
		try {
			await storeMeetApi(obj).then(() => {
				loadEventsMeetsByUserAuthenticated();
				setMsgAlert('Programación realizada con éxito');
				setIsError(false);
				setOpen(true);
				setTargetUserIdSelect(undefined);
				setTargetUserSelect(undefined)
			});
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				setMsgAlert(error.response.data.error);
				setIsError(true);
				setOpen(true);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setRequestInProgress(false);
		}
	}

	/**
	* Devuelve una lista de eventos que no están permitidos según la restricción. 
	* en la cual no pueden agendarse citas los fines de semana.
	* Estos eventos se muestran en el calendario con un color de fondo distinto.
	* 
	* @returns {any[]} Una lista de objetos de eventos que representan eventos no permitidos.
	*/
	const getWeekendsMeetsNotAllowed = (initialDate: Date, finalDate: Date) => {
		let weekendsMeetsList: any = [];
		let date = new Date(initialDate);
		while (date <= finalDate) {
			const dayOfWeek = date.getDay();
			//Sabado: 6, Domingo: 7
			if (dayOfWeek === 6 || dayOfWeek === 7) {
				weekendsMeetsList.push(new Date(date))
			}
			date.setDate(date.getDate() + 1);
		}

		let listEventsNotAllowed: any[] = [];
		weekendsMeetsList.forEach((item: any) => {
			let nextDay = new Date(item);
			nextDay.setDate(nextDay.getDate() + 1);
			const dateFormatted = convertDateToYYYYMMDD(item);

			let eventNotAllowed = {
				start: dateFormatted,
				end: dateFormatted,
				overlap: false,
				display: 'background',
				color: '#fab3ae',
				classNames: 'noClick',
				disabled: true,
				editable: false,
				allDay: true
			};
			listEventsNotAllowed.push(eventNotAllowed);
		});

		return listEventsNotAllowed;
	};

	/**
	* Devuelve una lista de eventos que no están permitidos según una restricción específica. 
	* Estos eventos se muestran en el calendario con un color de fondo distinto.
	* 
	* @returns {any[]} Una lista de objetos de eventos que representan eventos no permitidos.
	*/
	const getEventsNotAllowed = (todayColombia: Date) => {
		const todayMinusNYears = dateMinusNYears(todayColombia, 1);
		const todayFormatted = convertDateToYYYYMMDD(todayColombia);

		let listEventsNotAllowed: any[] = [];
		let eventNotAllowed = {
			start: todayMinusNYears,
			end: todayFormatted,
			overlap: false,
			display: 'background',
			color: '#fab3ae',
			classNames: 'noClick',
			editable: false,
			allDay: true,
			disabled: true,
		};
		listEventsNotAllowed.push(eventNotAllowed);
		return listEventsNotAllowed;
	};

	/**
	* Obtener los horarios disponibles de un consejero para un rango de horas, 
	* considerando la hora de inicio y fin de su jornada laboral.
	* 
	* @returns {any[]} Una lista con las horas de reunion posibles, de acuerdo a una hora de inicio y de fin.
	*/
	const generateMeetDataHour = (startHour: string, endHour: string) => {
		let scheduleList: HoursMeetsModel[] = [];
		let hourStart = startHour;
		while (hourStart < endHour) {
			const hourEnd = addMinutes(hourStart, DURATION_PEER_MEET);
			const hoursMeets: HoursMeetsModel = {
				start: hourStart,
				end: hourEnd
			};
			scheduleList.push(hoursMeets);		
			if (endHour === hourEnd) {
				break;
			}
			hourStart = addMinutes(hourStart, 5);
		}
		return scheduleList;
	}

	/**
	 * Valida si las horas se intesectan en la fran de tiempo
	 * @param hourStart 
	 * @param hourEnd 
	 * @param breakHourStart 
	 * @param breakHourEnd 
	 * @returns 
	 */
	function isIntersectingHoursRange(hourStart: string, hourEnd: string, breakHourStart: string, breakHourEnd: string): boolean {
		// Convertir las horas a números
		const hourStartNumber = convertMilitaryTimeTo24HourFormat(hourStart);
		const hourEndNumber = convertMilitaryTimeTo24HourFormat(hourEnd);
		const breakHourStartNumber = convertMilitaryTimeTo24HourFormat(breakHourStart);
		const breakHourEndNumber = convertMilitaryTimeTo24HourFormat(breakHourEnd);

		// Validar que las horas sean válidas
		if (hourStartNumber < 0 || hourStartNumber > 2400 || hourEndNumber < 0 || hourEndNumber > 2400 || breakHourStartNumber < 0
			|| breakHourStartNumber > 2400 || breakHourEndNumber < 0 || breakHourEndNumber > 2400) {
			return false;
		}

		// Validar que el rango de horas esté dentro del rango del break
		return (
			(hourStartNumber < breakHourStartNumber && hourEndNumber > breakHourStartNumber
				&& hourStartNumber < breakHourEndNumber && hourEndNumber < breakHourEndNumber) ||

			(hourStartNumber === breakHourStartNumber && hourEndNumber > breakHourStartNumber
				&& hourStartNumber < breakHourEndNumber && hourEndNumber < breakHourEndNumber) ||

			(hourStartNumber > breakHourStartNumber && hourEndNumber > breakHourStartNumber
				&& hourStartNumber < breakHourEndNumber && hourEndNumber < breakHourEndNumber) ||

			(hourStartNumber > breakHourStartNumber && hourEndNumber > breakHourStartNumber
				&& hourStartNumber < breakHourEndNumber && hourEndNumber === breakHourEndNumber) ||

			(hourStartNumber > breakHourStartNumber && hourEndNumber > breakHourStartNumber
				&& hourStartNumber < breakHourEndNumber && hourEndNumber > breakHourEndNumber) ||
			
			(hourStartNumber === breakHourStartNumber && hourEndNumber === breakHourEndNumber)
		);
	}

	/**
	* Obtén los horarios disponibles para un consejero en un rango de fechas determinado, 
	* considerando la fecha inicial y final, así como la hora inicio y fin de jornada del consejero
	* 
	* @returns {any[]} Una lista de las fechas con todas las horas de reunión posibles.
	*/
	const generateMeetDataMonth = (initialDate: Date, finalDate: Date, startHour: string, endHour: string) => {
		let meetsUserList = [] as MeetsUserModel[];
		const hoursMeetsUserList: HoursMeetsModel[] = generateMeetDataHour(startHour, endHour);
		let date = new Date(initialDate);
		while (date <= finalDate) {
			const meetsUser: MeetsUserModel = {
				fecha: new Date(date),
				horasReunionDisponibles: [],
				horasReunionNoDisponibles: [],
			}
			const dayOfWeek = date.getDay();
			//Dias diferentes a sabados y domingos
			if (dayOfWeek !== 0 && dayOfWeek !== 6) {
				meetsUser.horasReunionDisponibles = hoursMeetsUserList;
			} else {
				meetsUser.horasReunionNoDisponibles = hoursMeetsUserList;
			}
			meetsUserList.push(meetsUser);
			date.setDate(date.getDate() + 1);
		}
		return meetsUserList;
	}

	/**
	 * Remueve de las lista las franjas horarias de los break
	 * @param meetsUserList 
	 * @param breakStartHour 
	 * @param breakEndHour 
	 * @returns 
	 */
	const removeMeetsBetweenInterval = (meetsUserList: MeetsUserModel[], breakStartHour: string, breakEndHour: string) => {
		const filterMesetsUserList: MeetsUserModel[] = JSON.parse(JSON.stringify(meetsUserList));
		filterMesetsUserList.forEach(fmcl => {
			//Adiciona las horas no disponiles correspondientes al break
			for (const hourMeetAvailable of fmcl.horasReunionDisponibles) {
				if (isIntersectingHoursRange(hourMeetAvailable.start, hourMeetAvailable.end, breakStartHour, breakEndHour)) {
					fmcl.horasReunionNoDisponibles.push(hourMeetAvailable);
				}
			}
			fmcl.horasReunionDisponibles = fmcl.horasReunionDisponibles.filter((item) => !fmcl.horasReunionNoDisponibles.includes(item));
		});
		return filterMesetsUserList;
	}

	/**
	 * Obtiene la informacion de las citas del usuario autenticado
	 */
	async function loadEventsMeetsByUserAuthenticated() {
		setEventsMeetLoaded(false);
		try {
			let idUser = currentUser?.user?.id;
			let events: any[] = [];
			if (isCandidateRole()) {
				await getMeetsByCandidate(`id_candidate=${idUser}&vigentes=${vigentes}&activos=${cancelados}`).then((response) => {
					if (response.data) {
						response.data.forEach((element: any) => {
							const startDate = new Date(element.inicio);
							const isBeforeAtCurrentDate = startDate.getTime() < new Date().getTime();
							//Convierte la fecha en zona horaria UTC, desde el API se retorna en no UTC
							const dateLocalDate = convertUTCDateToLocalDate(startDate);
							let event = {
								id: element.id,
								publicId: element.id,
								//Muestra en el titulo el nombre del consejero con quien tiene el meet
								title: `${concatArrayToStringJoinBy([element.primer_nome, element.primer_apelido])}`,
								//Convierte la fecha en zona horaria UTC, desde el API se retorna en no UTC
								description: dateLocalDate.toLocaleString('es-CO', { timeZone: 'UTC' }),
								link_event: element.link_event,
								start: dateLocalDate.toISOString(),
								display: isBeforeAtCurrentDate ? 'auto' : 'block',
								overlap: true,
								extendedProps: {
									startDate: startDate,
									logrado: element.logrado,
									invitado: concatArrayToStringJoinBy([element.primer_nome, element.segundo_nome, element.primer_apelido, element.segundo_apelido]),
									activo: element.activo == 1 ? true : false,
									detalle: element.detalle
								},
								color: element.activo == 0 ? '#B22222' : element.logrado == true ? '#009900' : 'none'
							};
							events.push(event);
						});
					}
				});
			} else {
				await getMeetsByCouselor(`id_organizador=${idUser}&vigentes=${vigentes}&activos=${cancelados}`).then((response) => {
					if (response.data) {
						response.data.forEach((element: any) => {
							const startDate = new Date(element.inicio);
							const isBeforeAtCurrentDate = startDate.getTime() < new Date().getTime();
							//Convierte la fecha en zona horaria UTC, desde el API se retorna en no UTC
							const dateLocalDate = convertUTCDateToLocalDate(startDate);
							let event = {
								id: element.id,
								publicId: element.id,
								//Muestra en el titulo el nombre del candidato
								title: `${element.descripcion}`,
								//Convierte la fecha en zona horaria UTC, desde el API se retorna en no UTC
								description: dateLocalDate.toLocaleString('es-CO', { timeZone: 'UTC' }),
								link_event: element.link_event,
								start: dateLocalDate.toISOString(),
								display: isBeforeAtCurrentDate ? 'auto' : 'block',
								overlap: true,
								extendedProps: {
									startDate: startDate,
									logrado: element.logrado,
									invitado: element.descripcion,
									activo: element.activo == 1 ? true : false,
									detalle: element.detalle
								},
								color: element.activo == 0 ? '#B22222' : element.logrado == true ? '#009900' : 'none'
							};
							events.push(event);
						});
					}
				});
			}
			setEventsMeet(events);
			setEventsMeetLoaded(true);
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
			setEventsMeetLoaded(false);
		} finally { }
	}

	/**
	 * Obtiene la lista de de usuarios y configuracion de agendas disponibles. 
	 * Si el usuario que consulta la informacion es un candidato, toma la informacion de la configuracion de las agendas por cada consejero
	 * Si el usuario que consulta la informacion es un consejero ciudadano, toma la informacion de configuracion de la agenda de el mismo
	 * Adicionalmente la franja horaria de disponibilidad y la franja no disponible por break
	 */
	async function getMeetListUsersAvailables() {
		try {
			await meetListUsersAvailables(filterUserBy).then((response) => {
				if (response.data) {
					setListUsersAvailables(response.data);
				}
			});
		} catch (error: any) {
			if (error.response) {
				console.error(error.response?.data?.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
		}
	}

	/**
	 * Obtiene la lista de usuarios con informacion de disponibilidad
	 */
	async function getUsuariosMeets(month: number, year: number) {
		try {
			if (isCandidateRole()) {
				await listConsuelorMeets(month, year).then((response) => {
					if (response.data?.data) {
						setUsersMeetsList(response.data?.data || []);
					}
				});
			} else if (isCounselorCandidateRole()) {
				setRequestInProgress(true);
				await listCandidateAvailableMeets(filterUserBy, month, year).then((response) => {
					if (response.data) {
						setUsersMeetsList(response.data?.data || []);
					}
					setRequestInProgress(false);
				});
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
		}
	}

	/**
	 * Procesa las agendas de los usuarios, adicionando informacion de horarios disponibles y no disponibles
	 * @param meets 
	 * @param usersList 
	 * @param usersMeetsList 
	 */
	const processUserMeets = (meets: any[], usersList: any[], usersMeetsList: any[]) => {

		setEventsFullCalendarStyleInProgress(true);

		let events: any[] = JSON.parse(JSON.stringify(meets));
		const today = new Date();
		const todayColombia = new Date(new Date(today).toLocaleString('en-US', { timeZone: 'America/Bogota' }));
		const lastDayOfMonth = new Date(todayColombia.getFullYear(), todayColombia.getMonth() + 1, 0);
		lastDayOfMonth.setHours(23, 59, 59, 999);

		const monthFullCalendar = currentDateFullCalendar ? currentDateFullCalendar.getMonth() : 0;
		const yearFullCalendar = currentDateFullCalendar ? currentDateFullCalendar.getFullYear() : 0;
		let start = todayColombia;
		let end = lastDayOfMonth;

		//Si el año y el mes del componente FullCalendar NO son iguales al del sistema
		if (!(yearFullCalendar == todayColombia.getFullYear() && monthFullCalendar == todayColombia.getMonth())) {
			start = new Date(
				new Date(yearFullCalendar, monthFullCalendar, 1).toLocaleString('en-US', { timeZone: 'America/Bogota' })
			);
			end = new Date(yearFullCalendar, monthFullCalendar + 1, 0);
			end.setHours(23, 59, 59, 999);
		}

		usersList.forEach(cl => {

			const user = usersMeetsList.find((cml) => {
				if (isCandidateRole()) {
					return cml.consejeroId === cl.consejeroId
				}
				else if (isCounselorCandidateRole()) {
					return cml.candidateId === cl.candidateId
				}
			});
			cl.reuniones = user?.reuniones ? JSON.parse(JSON.stringify(user.reuniones)) : [];

			let startHour = cl.jornada.inicio;
			let endHour = cl.jornada.fin;

			let breakStartHour = cl.break.inicio;
			let breakEndHour = cl.break.fin;

			const generateMeetDataMonthList: any[] = generateMeetDataMonth(start, end, startHour, endHour);

			// Eliminar los posibles horarios de reunión que se cruzan con el break del consejero
			const meetsNotBreak: any[] = removeMeetsBetweenInterval(generateMeetDataMonthList, breakStartHour, breakEndHour);

			cl.agenda = JSON.parse(JSON.stringify(meetsNotBreak));

			// Eliminar los posibles horarios de reunión para reuniones ya agendadas para el consejero
			cl.reuniones.forEach((clr: any) => {

				const startMeetDate = new Date(clr.inicioReu);
				const startMeetDateString = startMeetDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

				const endMeetDate = new Date(clr.finReu);
				const endMeetDateString = endMeetDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

				const meetDate = new Date(clr.inicioReu);
				meetDate.setHours(0, 0, 0, 0);

				/**
				 * Obtiene coincidencia entre la fecha de la reunion y la agenda calculada
				 */
				const meetDayDate = cl.agenda.find((cla: any) => {
					const meetScheduleDay = new Date(cla.fecha);
					meetScheduleDay.setHours(0, 0, 0, 0);
					return meetDate.getTime() === meetScheduleDay.getTime();
				});

				if (meetDayDate) {
					for (const horaReunionDisponible of meetDayDate.horasReunionDisponibles) {
						if (isIntersectingHoursRange(horaReunionDisponible.start, horaReunionDisponible.end, startMeetDateString, endMeetDateString)) {
							meetDayDate.horasReunionNoDisponibles.push(horaReunionDisponible);
						}
					}
					meetDayDate.horasReunionDisponibles = meetDayDate.horasReunionDisponibles.filter((item: any) => !meetDayDate.horasReunionNoDisponibles.includes(item));

					const indice = cl.agenda.findIndex((cla: any) => {
						const meetScheduleDay = new Date(cla.fecha);
						meetScheduleDay.setHours(0, 0, 0, 0);
						return meetDate.getTime() === meetScheduleDay.getTime();
					});

					cl.agenda[indice] = JSON.parse(JSON.stringify(meetDayDate));
				}

			});

		});

		// Marca en el calendario como no disponibles, las fechas anteriores a la fecha actual
		const listEventsNotAllowed: any[] = getEventsNotAllowed(start);
		listEventsNotAllowed.forEach(item => {
			events.push(item)
		});
		// Marca en el calendario como no disponibles, los fines de semana en el mes
		const listWeekendsMeetsNotAllowed: any[] = getWeekendsMeetsNotAllowed(start, end);
		listWeekendsMeetsNotAllowed.forEach(item => {
			events.push(item)
		});

		setEventsMeet(events);
		setTargetUserScheduleList(usersList);
		setEventsFullCalendarStyleInProgress(false);

	}

	const [currentEvents, setCurrentEvents] = useState<EventApi[]>([]);

	/**
	 * Event eventsSet sobre date sets eventos FullCalendar
	 */
	const handleEvents = useCallback((events: EventApi[]) => setCurrentEvents(events), []);

	/**
	 * Llamado servicio eliminacion cita en la agenda
	 */
	async function confirmDelete() {
		if (!error && eliminarCita['id'] > 0) {
			setRequestInProgress(true);
			try {
				await deleteMeetApi(eliminarCita).then((response) => {
					setOpenModalInfoCita(false);
					loadEventsMeetsByUserAuthenticated();
					setMsgAlert('Programación eliminada con éxito');
					setIsError(false);
					setOpen(true);
					if (response.data) {
						handleCloseModalInfoCita();
						eventSelected.remove();
					}
				});
			} catch (error: any) {
				let mensaje = '';
				if (error.response) {
					mensaje = error.response.data.error;
				} else {
					mensaje = 'Ocurrio un error procesando la solicitud.'
				}
				setMsgAlert(mensaje);
				setIsError(true);
				setOpen(true);
			} finally {
				handleCloseDialogEliminarCita();
				setRequestInProgress(false);
			}
		}
	}

	/**
	 * Event click sobre una fecha del componente FullCalendar
	 */
	const handleEventClick = useCallback(async (clickInfo: EventClickArg) => {
		setEevntSelected(clickInfo.event);
		setOpenModalInfoCita(true);
	}, []);

	/**
	 * Event select sobre una fecha del componente FullCalendar
	 */
	const handleSelectEventFullCalendar = useCallback(async (clickInfo: DateSelectArg) => {
		setDaySelected(new Date(clickInfo.end));
		const dataI = new Date(clickInfo.end);
		const dataImonth = dataI.getMonth() + 1;
		if (dataI.getTime() < new Date().getTime()) {
			setMsgAlert('No es posible agendar una cita en una fecha anterior a la fecha actual');
			setIsError(true);
			setOpen(true);
		} else if (dataImonth < currentMonthFullCalendarRef.current || dataImonth > currentMonthFullCalendarRef.current) {
			setMsgAlert('Solo es posible agendar para el mes seleccionado');
			setIsError(true);
			setOpen(true);
		} else if (dataI.getDay() == 6 || dataI.getDay() == 0) {
			//Sabado: 6, Domingo: 0
			setMsgAlert('Programación no permitida durante el fin de semana');
			setIsError(true);
			setOpen(true);
			return;
		} else {
			let idUser = currentUser?.user?.id;
			if (isCandidateRole()) {
				await getMeetsByCandidate(`id_candidate=${idUser}&vigentes=true&activos=true`).then((response) => {
					if (response.data.filter((i: any) => i.activo == 1).length == 0) {
						setOpenModalCrearCita(true);
					} else {
						setMsgAlert('El candidato ya tiene una reunión programada');
						setIsError(true);
						setOpen(true);
					}
				}).catch((error) => {
					if (error.response) {
						console.error(error.response.data.error);
					} else {
						console.error('Ocurrio un error procesando la solicitud.');
					}
				});
			} else {
				setOpenModalCrearCita(true);
			}
		}
	}, []);

	/**
	 * Event datesSet en los button Today, next, previous FullCalendar
	 * @param payload 
	 */
	async function handleMonthChange(payload: any) {
		try {
			const dateFullCalendar = new Date(payload.view.currentEnd)
			//Prevent: Uncaught (in promise) Error: Maximum update depth exceeded. This can happen when a component repeatedly calls setState inside componentWillUpdate or componentDidUpdate. React limits the number of nested updates to prevent infinite loops.
			if (!currentDateFullCalendarRef?.current) {
				setCurrentDateFullCalendar(dateFullCalendar);
			} else if (currentDateFullCalendarRef.current.getMonth() !== dateFullCalendar.getMonth()) {
				setCurrentDateFullCalendar(dateFullCalendar);
			}
			let month = dateFullCalendar.getMonth() + 1;
			if (currentMonthFullCalendar !== month) {
				setCurrentMonthFullCalendar(month);
				//Se limpia la informacion de los eventos, para eliminar la referencia de lo que se haya cargado previamente
				setEventsMeet([]);
			}
		} catch (error) {
			console.error(error)
		}
	}

	/**
	 * onChange de TimePicker
	 * @param event 
	 */
	const handleOnChangeTimePicker = (event: any | null) => {
		if (event) {
			let date = new Date(event.$d);
			daySelected.setHours(date.getHours())
			daySelected.setMinutes(date.getMinutes());
			setDateMeet(daySelected);
			setTimeSelected(true)
			setHhourSelected(date.getHours());
			setMinuteSelected(date.getMinutes())
		} else {
			setTimeSelected(false)
			setDateMeet(null);
		}
	};

	const shouldDisableTime = (time: any | null, type: string) => {	
		
		//calcula el tiempo a agregar para obtener el tiempo final
		const adicionaMediaHora  = time.minute() + DURATION_PEER_MEET;		
		//convierte a string el tiempoIncial con formato HH:mm
		const tiempoInicial = time.format('HH:mm');		
		//Adiciona media hora con respecto al tiempo inicial y lo convierte a string y lo formatea a HH:mm
		const tiempoFinal = time.minute(adicionaMediaHora).format('HH:mm');	
		
		//Obtiene indice del calendario del usuario actual de acuerdo al dia seleccionado
		const currentUserSchedule: any = JSON.parse(JSON.stringify(userSchedule));
		const meetDate = daySelected;
		meetDate.setHours(0, 0, 0, 0);
		const index = currentUserSchedule?.agenda?.findIndex((csa: any) => {
			const meetScheduleDay = new Date(csa.fecha);
			meetScheduleDay.setHours(0, 0, 0, 0);
			return meetDate.getTime() === meetScheduleDay.getTime();
		});

		////Obtiene el array de las horas no disponibles
		const currentUserScheduleDay: MeetsUserModel = JSON.parse(JSON.stringify(currentUserSchedule?.agenda[index]));
		const horasReunionNoDisponibles = currentUserScheduleDay?.horasReunionNoDisponibles||[];
	
		//Recorre las horas no disponibles
		for (let i = 0; i < horasReunionNoDisponibles.length; i++) {
			const intervalo = horasReunionNoDisponibles[i];			
			const inicioTiempoNoDisponible = intervalo.start;			
			const finalizaTiempoNoDisponible = intervalo.end;			
	  
			//Aplica la validación, para el campo de minutos
			if ( type === 'minutes' 
				 && tiempoInicial === inicioTiempoNoDisponible 
				 && tiempoFinal === finalizaTiempoNoDisponible) {
				//opción de minuto deshabilitada
				return true;
			}
		}
		//opción de minuto habilitada
		return false;
	};

	/**
	 * Deshabilitar las horas del TimePicker en donde no es posible agendar
	 * @param event 
	 * @returns 
	 */
	const shouldDisableTime2 = (event: any | null, clockType: string) => {
		const isShouldDisableTime = false;
		if (userSchedule && event && ["hours", "minutes"].includes(clockType)) {

			const currentUserSchedule: any = JSON.parse(JSON.stringify(userSchedule));
			const meetDate = daySelected;
			meetDate.setHours(0, 0, 0, 0);

			const index = currentUserSchedule?.agenda?.findIndex((csa: any) => {
				const meetScheduleDay = new Date(csa.fecha);
				meetScheduleDay.setHours(0, 0, 0, 0);
				return meetDate.getTime() === meetScheduleDay.getTime();
			});

			if (event && index >= 0) {

				const currentUserScheduleDay: MeetsUserModel = JSON.parse(JSON.stringify(currentUserSchedule?.agenda[index]));
				
				let date = new Date(event.$d);
				let hours = date.getHours().toString().padStart(2, '0');
				let minutes = date.getMinutes().toString().padStart(2, '0');
				let startformattedDate = `${hours}:${minutes}`;

				/**
				 * Tratamiento especial para la hora en que se habilita la finalizacion del receso
				 * Ejemplo: Se identifico el problema que el receso del consejero suele terminar a las 13:30.
				 * Pero igual deshabilita toda la hora hasta la siguiente
				 */
				if (clockType == 'hours') {
					const timeEvent = convertTimeToMs(startformattedDate.split(':')[0], startformattedDate.split(':')[1])
					const startBreak = convertTimeToMs(currentUserSchedule?.break?.inicio.split(':')[0], currentUserSchedule?.break?.inicio.split(':')[1]);
					const endBreak = convertTimeToMs(currentUserSchedule?.break?.fin.split(':')[0], currentUserSchedule?.break?.fin.split(':')[1]);
					if (timeEvent >= startBreak && timeEvent <= endBreak) {
						return false;
					}
				}

				const today = new Date();
				const todayColombia = new Date(
					new Date(today).toLocaleString('en-US', {
						timeZone: 'America/Bogota'
					}),
				);

				const datePlus30Minutes = new Date(event.$d);
				datePlus30Minutes.setMinutes(datePlus30Minutes.getMinutes() + DURATION_PEER_MEET);

				hours = datePlus30Minutes.getHours().toString().padStart(2, '0');
				minutes = datePlus30Minutes.getMinutes().toString().padStart(2, '0');
				let endformattedDate = `${hours}:${minutes}`;

				const hoursMeetsModel: HoursMeetsModel = {
					start: startformattedDate,
					end: endformattedDate
				}
				
				let resultValidationDate: boolean = false;
				const isMeetingTimeNotAvailable = currentUserScheduleDay.horasReunionNoDisponibles.find(
					({ start, end }) => {						
						return start === hoursMeetsModel.start && end === hoursMeetsModel.end
					}
				);
				if (isMeetingTimeNotAvailable) {
					resultValidationDate = true
				}

				//Deshabilita las horas las horas que sean inferiores a la hora actual si y solo si la fecha seleccionada es la misma del sistema
				if (isDateOneLessThanDateTwo(daySelected, todayColombia)) {
					resultValidationDate = true
				}				
				return resultValidationDate;
			}
		}

		return isShouldDisableTime;
	};

	/**
	 * Aplica reglas de evaluacion de dias, horas y minutos para deshabilitar o no las horas y minutos en el TimePicker
	 * @param dateOne 
	 * @param dateTwo 
	 * @returns 
	 */
	const isDateOneLessThanDateTwo = (dateOne: Date, dateTwo: Date): boolean => {
		const hoursOne = dateOne.getHours();
		const minutesOne = dateOne.getMinutes();
		const hoursTwo = dateTwo.getHours();
		const minutesTwo = dateTwo.getMinutes();
		//No deshabilita horas o minutos si es el mismo dia. Para este caso las reglas se aplican en timePickerMinTime
		//TODO: revisar este comportamiento
		if (dateOne.getDay() === dateTwo.getDay()) {
			return false;
		} else if (dateOne > dateTwo) {
			return false;
		} else if (hoursOne < hoursTwo) {
			return true;
		} else if (hoursOne === hoursTwo && minutesOne < minutesTwo) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Listener de cambio de usuario
	 * @param event 
	 */
	const handleChangeUserSelected = (event: any) => {
		const valueSelected = event?.target?.value;
		if (valueSelected) {
			setTimeSelected(false)
			setHhourSelected(0);
			setMinuteSelected(0);
			setTargetUserIdSelect(valueSelected);
			setTargetUserSelect(valueSelected)
		}
	};

	/**
	 * Obtiene el tiempo mini,p habilitado segun el ininio de la jornada de disponibilidad para el consejero seleccionado
	 * @param targetUserIdSelect 
	 * @returns 
	 */
	const timePickerMinTime = (targetUserIdSelect: any) => {
		let hora = 8;
		let minuto = 0;
		if (targetUserIdSelect) {
			const targetUser = targetUserScheduleList.find((x: any) => (x.candidatoId || x.consejeroId) === targetUserIdSelect);
			if (targetUser) {
				const inicioJornada = targetUser?.jornada?.inicio;
				//Obtiene la hora actual del sistema
				const currentDate = new Date();
				//Cuando el dia seleccionado es el mismo dial del sistema
				if (daySelected.getDate() === currentDate.getDate()) {
					const finJornada = targetUser?.jornada?.fin;
					const currentHora = currentDate.getHours();
					const currentMinuto = currentDate.getMinutes();
					const jornadaInicioHora = Number(inicioJornada.split(':')[0]);
					const jornadaInicioMinuto = Number(inicioJornada.split(':')[1]);
					const jornadaFinHora = Number(finJornada.split(':')[0]);
					//Si la hora actual es menor a la horna del inicio de la jornada laboral				
					if (currentHora < jornadaInicioHora) {
						hora = jornadaInicioHora;
						minuto = jornadaInicioMinuto;
					} else if (currentHora > jornadaFinHora) {//Si la hora actual es mayor que la hora del fin de la jornada laboral					
						hora = jornadaFinHora + 1;
					} else {
						//Toma la hora del sistema
						hora = currentHora;
						minuto = currentMinuto;
					}
					return dayjs().set('hour', hora).set('minute', minuto).startOf('minute');
				} else {
					hora = Number(inicioJornada.split(':')[0]);
					//Toma la hora de inicio de la jornada laboral del candidato
					return dayjs().set('hour', hora).set('minute', minuto).startOf('minute');
				}
			}
		}
		return dayjs().set('hour', hora).set('minute', minuto).startOf('minute');
	}


	/**
	 * Obtiene el tiempo maximo habilitado segun el fin de la jornada de disponibilidad para el consejero seleccionado
	 * @param targetUserIdSelect 
	 * @returns 
	 */
	const timePickerMaxTime = (targetUserIdSelect: any) => {
		if (targetUserIdSelect) {
			const fin = targetUserScheduleList.find((x: any) => (x.candidatoId || x.consejeroId) === targetUserIdSelect)?.jornada?.fin
			if (fin) {
				let jornadaFinHora = fin.split(':')[0];
				let jornadaFinMinuto = fin.split(':')[1];
				const time = convertTimeToMs(jornadaFinHora, jornadaFinMinuto)
				const { hours, minutes } = convertMsToTime(time - (DURATION_PEER_MEET * 60000));//Se le restan 30 minutos
				return dayjs().set('hour', hours).set('minute', minutes).startOf('minute');
			}
		}
		const time = convertTimeToMs(18, 0)
		const { hours, minutes } = convertMsToTime(time - (DURATION_PEER_MEET * 60000));//Se le restan 30 minutos
		return dayjs().set('hour', hours).set('minute', minutes).startOf('minute');
	}

	/**
	 * helper text mensaje de error de componente TimePicker
	 * Este valor se actualiza con cualquier cambio sobre la variable: errorTimePicker
	 */
	const errorTimePickerMessage = useMemo(() => {
		switch (errorTimePicker) {
			case 'maxTime':
			case 'minTime': {
				return 'Por favor seleccione una hora válida según disponibilidad del usuario';
			}
			default: {
				return '';
			}
		}
	}, [errorTimePicker]);

	/**
	 * Valida reglas para habilitar el boton para confirmar una cita
	 * Regla: Que haya un consejero seleccionado, que se haya seleccionado tiempo y que no exista error con el tiempo
	 * seleccionado en el TimePicker
	 * @returns 
	 */
	const enableButtonStoreMeet = () => {
		return !errorTimePicker
			&& timeSelected
			&& dateMeet
			&& targetUserSelect
	}

	/**
	 * Deshabilita fechas anteriores al dia de hoy
	 * If true, disable values before the current date for date components, time for time components and both for date time components. 
	 * @returns 
	 */
	const disablePast = () => {
		return moment(daySelected).isBefore(new Date(), 'day')
	}

	const [openDialogEliminarCita, setOpenDialogEliminarCita] = useState(false);

	interface DetalleCita { id: number; detalle?: string; }
	const [eliminarCita, setEliminarCita] = useState<DetalleCita>({ id: 0, detalle: undefined });

	/**
	 * Open modal para justificar eliminacion de evento
	 * @param id 
	 */
	const handleOpenDialogEliminarCita = (id: any) => {
		setEliminarCita({ ...eliminarCita, detalle: undefined, id: id });
		setError(false);
		if (isCounselorCandidateRole()) {
			setOpenModalInfoCita(false);
			setOpenDialogEliminarCita(true);
		} else {
			confirmDelete();
		}
	};

	/**
	 * Close modal para justificar eliminacion de evento
	 */
	const handleCloseDialogEliminarCita = () => {
		setOpenDialogEliminarCita(false);
		setEliminarCita({ ...eliminarCita, detalle: undefined, id: 0 });
		setError(false);
	};

	const [error, setError] = useState(false);

	/**
	 * Event on change justificaicon eliminacion de evento
	 * @param event 
	 */
	const handleChangeDetalleEliminarCita = (event: any) => {
		setEliminarCita({ ...eliminarCita, detalle: event.target.value });
		if (event.target.value.length < 100 || event.target.value.length > 1200) {
			setError(true);
		} else {
			setError(false);
		}
	};

	/**
	* Opcion de filtrado para campo de candidato
	*/
	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		stringify: (option: UserTargetModel) => option.primerNombre + option.segundoNombre + option.primerApellido + option.segundoApellido + ' - ' + option.numeroDoc,
	});

	//Variables para los filtros
	const [cancelados, setCancelados] = useState('');
	const [vigentes, setVigentes] = useState('');

	//Consulta API al cambiar el valor de los filtros
	useEffect(() => {
		loadEventsMeetsByUserAuthenticated();
	}, [cancelados, vigentes]);

	//Determina si existe algun proceso ejecutandose 
	const checkAnyProcessInProgress = () => {
		return requestInProgress || !eventsMeetLoaded || eventsFullCalendarStyleInProgress;
	}

	/**
	 * Obtiene la informacion del usuario
	 */
	async function getUserInfo() {
		setAgendamientoHabilitado(true);
		try {
			if (isCounselorCandidateRole()) {
				const response = await getUserDataById(`id=${currentUser?.user?.id}`);
				setAgendamientoHabilitado((response?.data?.habilitarAgenda == true || response?.data?.habilitarAgenda == 1 || response?.data?.habilitarAgenda == '1') || false);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	}

	const [openDialogAtenderCita, setOpenDialogAtenderCita] = useState(false);
	const [atenderCita, setAtenderCita] = useState<DetalleCita>({ id: 0, detalle: undefined });

	/**
	* Event on change justificaicon atender de evento
	* @param event 
	*/
	const handleChangeDetalleAtenderCita = (event: any) => {
		setAtenderCita({ ...atenderCita, detalle: event.target.value });
		if (event.target.value.length < 100 || event.target.value.length > 4000) {
			setError(true);
		} else {
			setError(false);
		}
	};


	/**
	 * Close modal para justicar la atencion de la cita
	 */
	const handleCloseModalAtenderCita = () => {
		setOpenDialogAtenderCita(false);
		setEliminarCita({ ...atenderCita, detalle: undefined, id: 0 });
		setError(false);
	};

	/**
	 * Open modal para justificar evento atentito
	 * @param id 
	 */
	const handleOpenModalAtenderCita = (id: any) => {
		setAtenderCita({ ...atenderCita, detalle: undefined, id: id });
		setError(false);
		if (isCounselorCandidateRole()) {
			setOpenModalInfoCita(false);
			setOpenDialogAtenderCita(true);
		}
	};

	return (
		<>
			<PageTitle breadcrumbs={[]}>Citas programadas</PageTitle>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<div className='row mb-3'>
					<div className='col-12'>
						<div className='input-group d-flex' style={{ width: '100%' }}>
							<div style={{ padding: '10px 0px 0px 0px' }}>
								<div
									className='col-xs-12 col-sm-1 col-md-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center'
									}}
								>
									<BackButton />
								</div>
							</div>
							<div className='col-xs-12 col-sm-2 col-md-3 col-lg-3 col-xl-2 col-xxl-2' style={{ padding: '10px 0px 0px 15px' }}>
								<FormControl fullWidth size="small" sx={{ minWidth: 175, marginLeft: '8px' }} >
									<InputLabel id='cancelados'
										style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>Cancelados</InputLabel>
									<Select
										style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center', paddingTop: '2.48px', paddingBottom: '2.48px' }}
										labelId='cancelados'
										id='cancelados'
										label='Cancelados'
										value={initValue(cancelados)}
										onChange={(e) => {
											setCancelados(e.target.value);
										}}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										<MenuItem value='0'>Sí</MenuItem>
										<MenuItem value='1'>No</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className='col-xs-12 col-sm-2 col-md-3 col-lg-3 col-xl-2 col-xxl-2' style={{ padding: '10px 0px 0px 15px' }}>
								<FormControl fullWidth size="small" sx={{ minWidth: 175, marginLeft: '8px' }} >
									<InputLabel id='vigentes'
										style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>Vigentes</InputLabel>
									<Select
										style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center', paddingTop: '2.48px', paddingBottom: '2.48px' }}
										labelId='vigentes'
										id='vigentes'
										label='Vigentes'
										value={initValue(vigentes)}
										onChange={(e) => {
											setVigentes(e.target.value);
										}}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										<MenuItem value='1'>Sí</MenuItem>
										<MenuItem value='0'>No</MenuItem>
									</Select>
								</FormControl>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* {!eventsMeetLoaded ? <LinearLoadingSpinner isLoading={true} /> : */}
			<div className='p-4 shadow-4 rounded-3' style={{ backgroundColor: '#FFFF' }}>
				<LoadingBackdrop loading={checkAnyProcessInProgress()} />
				<div className='row'>
					<div className={`col-sm-${isCandidateRole() ? '8' : '12'} mb-${isCandidateRole() ? '6' : '12'}`}>
						<FullCalendar
							weekends={false}//Oculta los dias sabados y domingos
							plugins={[dayGridPlugin, interactionPlugin]}
							initialView='dayGridMonth'
							selectable={agendamientoHabilitado}
							editable={agendamientoHabilitado}
							events={eventsMeet}
							locales={allLocales}
							locale='es-co'
							eventsSet={(event) => handleEvents(event)}
							select={(event) => handleSelectEventFullCalendar(event)}
							eventClick={(event) => handleEventClick(event)}
							timeZone='UTC'
							datesSet={(event) => handleMonthChange(event)}
							hiddenDays={[0]}//Oculta solo los dias domingos
						/>
					</div>
					{isCandidateRole() &&
						<div className='col-sm-4'>
							<div className='card mb-10' style={{ borderRadius: '10px' }}>
								<div className='card-body scrollable' style={{ paddingTop: '7px' }}>
									<h3 className='card-title'>Consejeros disponibles</h3>
									{listUsersAvailables.map((x: any, i: number) => (
										<div className='row mt-6' style={{ flexWrap: 'nowrap' }} key={i} id={x.id}>
											<div className='col-sm-1' style={{ width: 'auto' }}>
												<div className={`box-aside`}>
													<Avatar
														size='60px'
														alt={`${concatArrayToStringJoinBy([x.primerNombre, x.primerApellido])}`}
														round={'11px'}
														name={`${concatArrayToStringJoinBy([x.primerNombre, x.primerApellido])}`}
													/>
												</div>
											</div>
											<div className='col-sm-9 mt-2' style={{ width: 'auto' }}>
												<span className='text-primary'>{concatArrayToStringJoinBy([x.primerNombre, x.primerApellido])}</span>
												<br />
												<span className='text-muted'>Horarios disponibles</span>
												<br />
												<span>
													{convertMilitaryTimeTo12HourFormat(x.jornada?.inicio)} -{' '}
													{convertMilitaryTimeTo12HourFormat(x.break?.inicio)}
												</span>
												<br />
												<span>
													{convertMilitaryTimeTo12HourFormat(x.break?.fin)} -{' '}
													{convertMilitaryTimeTo12HourFormat(x.jornada?.fin)}
												</span>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					}
				</div>
			</div>
			{/* } */}
			<Snackbar
				open={open}
				autoHideDuration={autoHideDuration}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant='filled'
					severity={isError ? 'error' : 'success'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{msgAlert}
				</Alert>
			</Snackbar>

			{/* MODAL */}
			<Modal
				open={openModalCrearCita}
				onClose={handleCloseModalCrearCita}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				disableEscapeKeyDown				
			>
				<Box
					sx={{
						position: 'absolute' as 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: isCandidateRole() == true ? '25vw' : '35vw',
						bgcolor: 'background.paper',
						border: 'none',
						borderRadius: '10px',
						boxShadow: 24,
						p: 4,
					}}
				>
					<Typography id='modal-modal-title' variant='h5' component='h2' mb={2}>
						<b>Nueva reunión el {moment(daySelected).format('DD/MM/YYYY')}</b>
					</Typography>					
					<Grid container direction='row' id="modal-modal-description" justifyContent='space-between' width={'100%'} mb={2}>
						<Grid xs={12} item={true} mb={1.5}>
							<Typography variant='inherit' sx={{ fontSize: '10pt' }}>
								Seleccione {(isCandidateRole() ? 'Consejero' : 'Candidato')+' para poder diligenciar la hora'}
							</Typography>
						</Grid>
						<Grid xs={12} item={true} mb={1.5}>							
							<FormControl sx={{ minWidth: '100%' }} size='medium'>
								{isCandidateRole() ? (
									<>
										<InputLabel id='tagetUser'></InputLabel>
										<Select
											labelId='tagetUser'
											id='tagetUser'
											//label={isCandidateRole() ? 'Consejero:' : 'Candidato:'}
											value={targetUserIdSelect}
											onChange={(event) => {
												handleChangeUserSelected(event);
												if(event?.target?.value == ''){
													sethabilitarHora(false);
												} else {
													sethabilitarHora(true);
												}												
											}}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{targetUserScheduleList.map(({ consejeroId, candidatoId, primerNombre, primerApellido }: { consejeroId: number, candidatoId: number, primerNombre: string, primerApellido: string }) => (
												<MenuItem key={candidatoId || consejeroId} value={candidatoId || consejeroId}>
													{primerNombre}&nbsp;{primerApellido}
												</MenuItem>
											))}
										</Select>
									</>
								) : (
									<>
										<Typography variant='inherit' sx={{ fontSize: '9pt' }} mb={1.5}>
											Para realizar una búsqueda, digite el criterio y presione Enter.
										</Typography>									
										<Autocomplete
											sx={{ minWidth: '100%' }}
											size='medium'
											id="tagetUser"
											getOptionLabel={(opt: UserTargetModel) => initValue(concatArrayToStringJoinBy([opt.primerNombre, opt.segundoNombre, opt.primerApellido, opt.segundoApellido]))}
											isOptionEqualToValue={(opt, value) => (opt && value) && opt.candidatoId === value.candidatoId}
											options={targetUserScheduleList}
											filterOptions={filterOptions}
											onChange={(e: any, newValue: UserTargetModel | null) => {
												const valueSelected: any = newValue;
												if (valueSelected) {
													setTimeSelected(false)
													setHhourSelected(0);
													setMinuteSelected(0);
													setTargetUserIdSelect(valueSelected.candidatoId);
													
												}
												setTargetUserSelect(valueSelected);
												handleOnChangeTimePicker(null)
												if(valueSelected == undefined){
													sethabilitarHora(false);
												} else {
													sethabilitarHora(true);
												}											
											}}
											onInputChange={(event, newInputValue) => {
												setFilterUserBy(newInputValue)
											}}										
											renderOption={(props, option) => {
												return (
													<li {...props} key={option.candidatoId}>
														{concatArrayToStringJoinBy([option.primerNombre, option.segundoNombre, option.primerApellido, option.segundoApellido]) + ' - ' + option.numeroDoc}
													</li>
												);
											}}
											//open={!requestInProgress}
											onKeyDown={async (event) => {
												if (event.key === 'Enter') {
													setRequestInProgress(true)
													//Obtiene lista de consejeros disponibles. Si el usuario es consejero solo se consulta asi mismo		
													await getMeetListUsersAvailables();
													//Obtiene informacion de disponibilidad, jornada laboral y meets de los usuarios objeto para agendar
													await getUsuariosMeets(currentMonthFullCalendar, currentDateFullCalendar ? currentDateFullCalendar?.getFullYear() : new Date().getFullYear());
													setRequestInProgress(false)
												}
											}}
											renderInput={(params) => <TextField {...params} label="Buscar por nombres / número documento" inputProps={{ ...params.inputProps, required: true }} required={true} />}
											disabled={requestInProgress}
											noOptionsText={'No se encontraron resultados'}
										/>
									</>
								)}
							</FormControl>
						</Grid>
						{isCounselorCandidateRole() && (
							<Grid xs={12} item={true} mb={1.5}>
								{requestInProgress && (
									<Box sx={{ width: '100%' }}>
										<LinearProgress />
									</Box>
								)}
							</Grid>
						)}
						{habilitarHora && (
							<>
								<Grid xs={12} item={true} mb={1.5}>
									<Typography variant='inherit' sx={{ fontSize: '10pt' }}>
										Seleccione Hora:
									</Typography>
								</Grid>
								<Grid xs={12} item={true}>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<Box width={'100%'}>
											<DemoItem>
												<TimePicker
													label='Hora de la reunión:'											
													//views={['hours', 'minutes']}
													//defaultValue={dayjs(moment().format("YYYY-MM-DDTHH:mm"))}+
													//Deshabilita tiempos pasados
													disablePast={disablePast()}
													//Formato fecha AM / PM
													ampm={true}
													//onChange
													onChange={(newValue) => handleOnChangeTimePicker(newValue)}
													//Disable specific time
													/* shouldDisableTime={(timeValue, clockType) => {
														return shouldDisableTime(timeValue, clockType);
													}} */
													shouldDisableTime={shouldDisableTime}
													//Hora minima, segun jornada laboral del consejero seleciconado u otras condiciones
													minTime={timePickerMinTime(targetUserIdSelect)}
													//Hora maxima, segun jornada laboral del consejero seleccionado
													maxTime={timePickerMaxTime(targetUserIdSelect)}
													//Error
													onError={(newError) => setErrorTimePicker(newError)}
													//Do not ignore date part when validating min/max time.
													disableIgnoringDatePartForTimeValidation={false}
													//readOnly when exists request in progress
													readOnly={requestInProgress}
												//Clear action
												//slotProps={{ actionBar: { actions: ['clear'] }, }}
												/>
											</DemoItem>
										</Box>
									</LocalizationProvider>
								</Grid>
								{errorTimePicker && (												
									<Grid xs={12} item={true} mb={1.5}>
										<p className='messageError' id=':r0:-helper-text'>
										{errorTimePickerMessage}
										</p>
									</Grid>	
								)}											
								<Grid xs={12} item={true} mb={1.5}>
									<Typography color={'gray'} variant='caption' sx={{ fontSize: '8pt' }}>
										Haga clic en el ícono del reloj para cambiar la hora.
									</Typography>
								</Grid>
							</>
						)}
					</Grid>			
					<div className='col d-flex justify-content-end'>
						{enableButtonStoreMeet() ? (
							<button
								className='btn btn-primary'
								onClick={() => { 
									sethabilitarHora(false); 
									storeMeet(); 
								}}
								style={{ padding: '8px 20px', background: '#153E7B' }}
								disabled={requestInProgress}
							>
								Confirmar
							</button>
						) : null}
						&nbsp;
						<button
							className='btn btn-secondary'
							onClick={() => {
								setTimeSelected(false)
								handleCloseModalCrearCita();
								setErrorTimePicker(null);
								sethabilitarHora(false);
							}}
							style={{ padding: '8px 20px' }}
							disabled={requestInProgress}
						>
							Cancelar
						</button>
					</div>
				</Box>
			</Modal >
			{/* Dialog de la cita */}
			<Modal
				open={openModalInfoCita}
				onClose={handleCloseModalInfoCita}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				disableEscapeKeyDown
			>
				<Box
					sx={{
						position: 'absolute' as 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '50%',
						bgcolor: 'background.paper',
						border: '2px solid #000',
						borderRadius: '10px',
						boxShadow: 24,
						p: 4,
					}}
				>
					<DialogTitle id='customized-dialog-title' variant='h4' component='h2'>
						<b>Información Citación</b>
					</DialogTitle>
					<DialogContent dividers style={{ overflow: "auto" }}>
						<Grid xs={12} mb={2} item>
							<Typography variant='inherit' sx={{ fontSize: '10pt' }} mb={0.5}>
								<b>Fecha:</b> {moment(eventSelected?.extendedProps?.startDate).format('DD-MM-YYYY')}
							</Typography>						
							<Typography variant='inherit' sx={{ fontSize: '10pt' }} mb={0.5}>
								<b>Hora:</b> {moment(eventSelected?.extendedProps?.startDate).format('HH:mm A')}
							</Typography>						
							<Typography variant='inherit' sx={{ fontSize: '10pt' }} mb={0.5}>
								<b> {isCandidateRole() ? `Consejero` : `Candidato`} :</b> {eventSelected?.extendedProps?.invitado}
							</Typography>						
							{eventSelected?.extendedProps?.activo == true && !(new Date(eventSelected?.extendedProps?.startDate).getTime() >= new Date().getTime()) && (
								<>
									<Typography variant='inherit' sx={{ fontSize: '10pt' }} mb={0.5}>
										<b>¿Cita atendida?:</b> {eventSelected?.extendedProps?.logrado == true ? 'Sí' : 'No'}
									</Typography>								
								</>
							)}
							{eventSelected?.extendedProps?.detalle && (
								<>
									<Typography variant='inherit' sx={{ fontSize: '10pt' }}> 
										{eventSelected?.extendedProps?.activo == false ? <b>Motivo cancelación:</b> : <b>Detalle:</b>}
									</Typography>
									<Typography variant='inherit' style={{ overflow: "auto" }} noWrap={false} align="justify" paragraph={true} sx={{ fontSize: '10pt' }} mb={0.5}>
										{eventSelected?.extendedProps?.detalle}
									</Typography>								
								</>
							)}
							<Typography variant='inherit'mt={0.5}>
								<a href={eventSelected?.extendedProps?.link_event ?? ''} target='_blank' rel="noreferrer">
									Ver en el calendario
								</a>
							</Typography>	
						</Grid>					
						<div className='col d-flex justify-content-end'>
							<button
								className='btn btn-secondary'
								onClick={() => { handleCloseModalInfoCita(); }}
								style={{ padding: '8px 20px' }}
								disabled={requestInProgress}
							>
								Cerrar
							</button>
							&nbsp;
							{eventSelected?.extendedProps?.activo == true && !(new Date(eventSelected?.extendedProps?.startDate).getTime() < new Date().getTime()) && agendamientoHabilitado == true ? (
								<button
									className='btn btn-danger'
									onClick={() => { handleOpenDialogEliminarCita(eventSelected?.extendedProps?.publicId); }}
									style={{ padding: '8px 20px', background: '#d41717' }}
									disabled={requestInProgress}
								>
									Eliminar
								</button>
							) : (
								<>
									{!isCandidateRole() && eventSelected?.extendedProps?.activo == true && eventSelected?.extendedProps?.logrado == false && agendamientoHabilitado == true && (
										<button
											className='btn boton-atendido'
											onClick={() => { handleOpenModalAtenderCita(eventSelected.extendedProps.publicId); }}											
											disabled={requestInProgress}
										>
											Marcar como atendida
										</button>
									)}
								</>
							)}
						</div>
					</DialogContent>
				</Box>
			</Modal>
			<div>
				{/* Modal eliminar cita */}
				<Modal						
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
					disableEscapeKeyDown
					open={openDialogEliminarCita}
					keepMounted
					onClose={handleCloseDialogEliminarCita}									
				>
					<Box
						sx={{
							position: 'absolute' as 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: '55%',
							bgcolor: 'background.paper',
							border: '2px solid #000',
							borderRadius: '10px',
							boxShadow: 24,
							p: 4,
						}}
					>
						<DialogTitle id='alert-dialog-title' variant='h4' component='h2'>
							<b>¿Eliminar Cita?</b>
						</DialogTitle>
						<DialogContent dividers>
							<Typography id = 'modal-modal-description' variant='inherit' sx={{ fontSize: '10pt' }} mb={1}>
								¿Está seguro de que desea eliminar la cita? Escriba las razones por las que elimina la cita a continuación:
							</Typography>												
							<FormControl fullWidth>
								<TextField
									fullWidth								
									rows={5}
									id='observaciones'
									name='observaciones'
									label='Observaciones'
									required
									error={error}
									value={initValue(eliminarCita['detalle'])}
									onChange={handleChangeDetalleEliminarCita}
									size='medium'
									multiline
									inputProps={{ maxLength: 1200, minLength: 100 }}
									disabled={requestInProgress}
									helperText={(error ? 'La observación debe tener entre 100 y 1200 caracteres.' : '') + ` Caracteres actuales: ${eliminarCita['detalle'] === undefined ? '0' : eliminarCita['detalle']?.length}`}
								/>
							</FormControl>
						</DialogContent>
						<DialogActions>
							<Button
								variant='contained'
								sx={{ background: '#960a36', width: 90 }}
								onClick={handleCloseDialogEliminarCita}
								autoFocus
								disabled={checkAnyProcessInProgress()}
							>
								Cancelar
							</Button>
							<Button
								variant='contained'
								sx={{ background: '#0A4396', width: 90 }}
								onClick={() => confirmDelete()}
								autoFocus
								disabled={checkAnyProcessInProgress() || !isValidMinMaxText(eliminarCita['detalle'], 100, 1200, true)}
							>
								<span style={{ display: 'flex', alignItems: 'center' }}>
									{checkAnyProcessInProgress() && isValidMinMaxText(eliminarCita['detalle'], 100, 1200, true) && <CircularProgress size={20} style={{ marginRight: 2 }} />}
									{'Enviar'}
								</span>
							</Button>
						</DialogActions>
					</Box>
				</Modal>
				
				{/* Dialog marcar atendida una cita */}
				<Modal
					open={openDialogAtenderCita}
					keepMounted
					onClose={handleCloseModalAtenderCita}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
					disableEscapeKeyDown				
				>
					<Box
						sx={{
							position: 'absolute' as 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: '55%',
							bgcolor: 'background.paper',
							border: '2px solid #000',
							borderRadius: '10px',
							boxShadow: 24,
							p: 4,
						}}
					>	
						<DialogTitle id='alert-dialog-title' variant='h4' component='h2'>
							<b>Atender Cita</b>
						</DialogTitle>
						<DialogContent dividers>
							<Typography id = 'modal-modal-description' variant='inherit' sx={{ fontSize: '10pt' }} mb={1}>							
								A continuación proporcioné el detalle de lo realizado durante la cita:
							</Typography>
							<FormControl fullWidth>
								<TextField
									fullWidth									
									rows={5}
									id='observaciones'
									name='observaciones'
									label='Observaciones'
									required
									error={error}
									value={initValue(atenderCita['detalle'])}
									onChange={handleChangeDetalleAtenderCita}
									size='medium'
									multiline
									inputProps={{ maxLength: 4000, minLength: 100 }}
									disabled={requestInProgress}
									helperText={(error ? 'La observación debe tener entre 100 y 4000 caracteres.' : '') + ` Caracteres actuales: ${atenderCita['detalle'] === undefined ? '0' : atenderCita['detalle']?.length}`}
								/>
							</FormControl>
						</DialogContent>
						<DialogActions>
							<Button
								variant='contained'
								sx={{ background: '#960a36', width: 90 }}
								onClick={handleCloseModalAtenderCita}
								autoFocus
								disabled={checkAnyProcessInProgress()}
							>
								Cancelar
							</Button>
							<Button
								variant='contained'
								sx={{ background: '#0A4396', width: 90 }}
								onClick={() => confirmDoneMeet()}
								autoFocus
								disabled={checkAnyProcessInProgress() || !isValidMinMaxText(atenderCita['detalle'], 100, 4000, true)}
							>
								<span style={{ display: 'flex', alignItems: 'center' }}>
									{checkAnyProcessInProgress() && isValidMinMaxText(atenderCita['detalle'], 100, 4000, true) && <CircularProgress size={20} style={{ marginRight: 2 }} />}
									{'Enviar'}
								</span>
							</Button>
						</DialogActions>
					</Box>
				</Modal>
			</div>
		</>
	);

};

export default CustomCalendarComponent;
