import { FormEvent, useContext, useEffect, useState } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
	TextField,
	SelectChangeEvent,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Button,
	Box,
	Typography,
	Divider,
	Alert,
	Snackbar,
	AlertTitle,
	FormHelperText,
	InputAdornment
} from '@mui/material';
import { multiStepContext } from '../../../../../contexts/CurriculumContext';
import { createCurriculo, getNivelesEducativos } from '../../../../../modules/apps/user-management/users-list/core/_requests';
import {
	CapacitacionCertificacionModel,
	ComplementoDirecaoModel,
	HerramientasModel,
	IdiomaModel,
	PuestosModel,
	NivelEducativoModel,
	ResumeModel,
	UserComplementoModel,
	ExperienciaLaboralModel,
} from '../../../../../models/ResumeModel';
import {
	formatNumber,
	isValidFieldRequired,
	setValueOrDefault,
	salarioMinIsGreaterThanSalarioMax,
	helperSalarioMinIsGreaterThanSalarioMax
} from '../../../../../util/Index';

import useStep3Parameters from '../../../../../hooks/parameters/curriculum/useStep3Parameters';
import { LinearLoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';


export const Step3 = () => {
	const { setStep, userData, setUserData, setCurrentProgress, setLoading } = useContext(multiStepContext);
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [touchedFields, setTouchedFields] = useState<string[]>([]);
	const [step, setStepUser] = useState<string>('step3');
	const { licenceCategoryList, licenceCategoryMotorList,
		listTipoOtrosConocimientos,
		listSalario,
		listSalarioMensual,
		listSiNo,
		listSituacionLaboral,
		loadingParams } = useStep3Parameters();

	const [listNivelEducativo, setListNivelEducativo] = useState<any[]>([]);

	const CODIGO_SI = '1';

	const handleValidOnSubmit = () => {

		let errors: { field: string; error: string }[] = [];

		let camposError: string[] = [];

		if (!isValidFieldRequired(userData['possibilidade_viajar'])){
			errors.push({ field: 'Posibilidad de viajar', error: 'Campo requerido' });
			camposError.push('possibilidade_viajar');
		}
		if (!isValidFieldRequired(userData['situacion_laboral_actual'])){
			errors.push({ field: 'Situación laboral actual', error: 'Campo requerido' });
			camposError.push('situacion_laboral_actual');
		}

		if (!isValidFieldRequired(userData['ultimo_salario'])){
			errors.push({ field: 'ultimo_salario', error: 'Campo requerido' });
			camposError.push('ultimo_salario');
		}

		if (!isValidFieldRequired(userData['aspiracion_salarial'])){
			errors.push({ field: 'Aspiración salarial', error: 'Campo requerido' });
			camposError.push('aspiracion_salarial');
		}
		else if (!isValidFieldRequired(userData['aspiracion_salarial_min'])){
			errors.push({ field: 'Aspiración salarial mínima', error: 'Campo requerido' });
			camposError.push('aspiracion_salarial_min');
		}
		else if (!salarioMinIsGreaterThanSalarioMax(userData['aspiracion_salarial_min'], userData['aspiracion_salarial'])){
			errors.push({ field: 'Aspiración salarial y Aspiración salarial mínima', error: helperSalarioMinIsGreaterThanSalarioMax(userData['aspiracion_salarial_min'], userData['aspiracion_salarial'], 'El salario mínimo aceptado no puede ser mayor a la expectativa salarial') });
			camposError.push('aspiracion_salarial_min');
		}

		if (!isValidFieldRequired(userData['Interes_ofertas_teletrabajo'])){
			errors.push({ field: 'Interés en ofertas de teletrabajo', error: 'Campo requerido' });
			camposError.push('interes_ofertas_teletrabajo');
		}

		if (!isValidFieldRequired(userData['posibilidad_trasladarse'])){
			errors.push({ field: 'Posibilidad de trasladarse', error: 'Campo requerido' });
			camposError.push('posibilidad_trasladarse');
		}

		if (!isValidFieldRequired(userData['conduces_carro'])){
			errors.push({ field: 'Conduce carro', error: 'Campo requerido' });
			camposError.push('conduces_carro');
		}
		else if (userData['conduces_carro'] == CODIGO_SI){
			if (!isValidFieldRequired(userData['categoria_licencia_carro'])){
				errors.push({ field: 'Categoría licencia para carro', error: 'Campo requerido' });
				camposError.push('categoria_licencia_carro');
			}
		}

		if (!isValidFieldRequired(userData['conduces_moto'])){
			errors.push({ field: 'Conduce moto', error: 'Campo requerido' });
			camposError.push('conduces_moto');
		}
		else if (userData['conduces_moto'] == CODIGO_SI){
			if (!isValidFieldRequired(userData['categoria_licencia_moto'])){
				errors.push({ field: 'Categoría licencia para moto', error: 'Campo requerido' });
				camposError.push('categoria_licencia_moto');
			}
		}
		
		if (!isValidFieldRequired(userData['Prop_medio_transporte'])){
			errors.push({ field: 'Propietario_medio_transporte', error: 'Campo requerido' });
			camposError.push('prop_medio_transporte');
		}

		if (!isValidProfile(userData['perfil_laboral'])){
			errors.push({
				field: 'perfil_laboral',
				error: msgProfile(userData['perfil_laboral']).split('\n')[0],
			});
			camposError.push('perfil_laboral');
		}


		if (!isValidFieldRequired(userData['nivel_educativo_max'])){
			errors.push({ field: 'Nivel educativo máximo', error: 'Campo requerido' });
			camposError.push('nivel_educativo_max');
		}
	
		setTouchedFields(camposError)

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\Diligencie los campos obligatorios\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			return false;
		}

		return true;
	};

	async function handleSubmit(event: FormEvent) {
		const retorno = handleValidOnSubmit();
		if (retorno) {
			setLoading(true);
			try {
				event.preventDefault();
				let object = createObjectToSubmit();
				const response: any = await createCurriculo(object);
				setAlert(true);
				setAlertMsg(`¡Registro completado con éxito!`);
				setOpen(true);
				setStep(4);
				setCurrentProgress(response?.data?.porcentajeDiligenciamiento);
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
					setOpen(true);
					setAlert(false);
					setAlertMsg(error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setLoading(false);
			}
		}
	}

	const createObjectDirecao = (): ComplementoDirecaoModel[] => {
		let compDirecaoArr: ComplementoDirecaoModel[] = [];
		if (
			userData['complementoDirecao'] !== null &&
			userData['complementoDirecao'] !== undefined &&
			(userData['complementoDirecao'] as Array<any>).length > 0
		) {
			(userData['complementoDirecao'] as Array<any>).forEach((e) => {
				let x: ComplementoDirecaoModel = {
					complemento: setValueOrDefault(e['complemento'], 'string'),
					id: setValueOrDefault(e['id'], 'number'),
					tipo_complemento: setValueOrDefault(e['tipo_complemento'], 'string'),
				};
				compDirecaoArr.push(x);
			});
		}

		return compDirecaoArr;
	};

	const createObjectPuestos = (): PuestosModel[] => {
		let puestosArr: PuestosModel[] = [];

		if (
			userData['listPuestos'] !== null &&
			userData['listPuestos'] !== undefined &&
			(userData['listPuestos'] as Array<any>).length > 0
		) {
			(userData['listPuestos'] as Array<any>).forEach((e) => {
				let x: PuestosModel = {
					id: setValueOrDefault(e['id'], 'number'),
					puestos: setValueOrDefault(e['puestos'], 'string'),
				};
				puestosArr.push(x);
			});
		}

		return puestosArr;
	};

	const createObjectNivelEducativo = (): NivelEducativoModel[] => {
		let nivelEducativoArr: NivelEducativoModel[] = [];

		if (
			userData['nivelEducativo'] !== null &&
			userData['nivelEducativo'] !== undefined &&
			(userData['nivelEducativo'] as Array<any>).length > 0
		) {
			(userData['nivelEducativo'] as Array<any>).forEach((e) => {
				let x: NivelEducativoModel = {
					id: setValueOrDefault(e['id'], 'number'),
					areaDesempeno: setValueOrDefault(e['areaDesempeno'], 'string'),
					estadoDelCurso: setValueOrDefault(e['estadoDelCurso'], 'string'),
					idObservaciones: setValueOrDefault(e['idObservaciones'], 'string'),
					txtNumeroTarjProf: setValueOrDefault(e['txtNumeroTarjProf'], 'string'),
					fechaTarjProf: setValueOrDefault(e['fechaTarjProf'], 'string'),
					interessadoPractica: setValueOrDefault(e['interessadoPractica'], 'boolean'),
					nivelEducativo: setValueOrDefault(e['nivelEducativo'], 'string'),
					nucleoConocimiento: setValueOrDefault(e['nucleoConocimiento'], 'string'),
					paisDelCurso: setValueOrDefault(e['paisDelCurso'], 'string'),
					tituloHomologado: setValueOrDefault(e['tituloHomologado'], 'string'),
					tituloObtenido: setValueOrDefault(e['tituloObtenido'], 'string'),
				};
				nivelEducativoArr.push(x);
			});
		}

		return nivelEducativoArr;
	};

	const createObjectCapacitacionCertificacion = (): CapacitacionCertificacionModel[] => {
		let capCertificacionesArr: CapacitacionCertificacionModel[] = [];
		if (
			userData['capacitacionCertificacion'] !== null &&
			userData['capacitacionCertificacion'] !== undefined &&
			(userData['capacitacionCertificacion'] as Array<any>).length > 0
		) {
			(userData['capacitacionCertificacion'] as Array<any>).forEach((e) => {
				let x: CapacitacionCertificacionModel = {
					id: setValueOrDefault(e['id'], 'number'),
					duracionHoras: setValueOrDefault(e['duracionHoras'], 'string'),
					estadoCapacitacione: setValueOrDefault(e['estadoCapacitacione'], 'string'),
					fechaCertificacion: setValueOrDefault(e['fechaCertificacion'], 'string'),
					instituicion: setValueOrDefault(e['instituicion'], 'string'),
					nombrePrograma: setValueOrDefault(e['nombrePrograma'], 'string'),
					paisCapacitacione: setValueOrDefault(e['paisCapacitacione'], 'string'),
					tpCapacitacionCertificacion: setValueOrDefault(
						e['tpCapacitacionCertificacion'],
						'string'
					),
				};
				capCertificacionesArr.push(x);
			});
		}

		return capCertificacionesArr;
	};

	const createObjectIdioma = (): IdiomaModel[] => {
		let idiomaArr: IdiomaModel[] = [];
		if (
			userData['listIdioma'] !== null &&
			userData['listIdioma'] !== undefined &&
			(userData['listIdioma'] as Array<any>).length > 0
		) {
			(userData['listIdioma'] as Array<any>).forEach((e) => {
				let x: IdiomaModel = {
					id_idioma: setValueOrDefault(e['id_idioma'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id: setValueOrDefault(e['id'], 'string'),
				};
				idiomaArr.push(x);
			});
		}
		return idiomaArr;
	};

	const createObjectHerramienta = (): HerramientasModel[] => {
		let herramientasArr: HerramientasModel[] = [];
		if (
			userData['listHerramientas'] !== null &&
			userData['listHerramientas'] !== undefined &&
			(userData['listHerramientas'] as Array<any>).length > 0
		) {
			(userData['listHerramientas'] as Array<any>).forEach((e) => {
				let x: HerramientasModel = {
					id: setValueOrDefault(e['id'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id_tipo_conocimientos: setValueOrDefault(
						listTipoOtrosConocimientos.find((y) => y.value === e['tipo'])?.code,
						'string'
					),
					herramientas: setValueOrDefault(e['herramientas'], 'string'),
				};
				herramientasArr.push(x);
			});
		}

		return herramientasArr;
	};

	const createObjectExpLaboral = (): ExperienciaLaboralModel[] => {
		let expLaboralArr: ExperienciaLaboralModel[] = [];
		if (
			userData['experienciaLaboral'] !== null &&
			userData['experienciaLaboral'] !== undefined &&
			(userData['experienciaLaboral'] as Array<any>).length > 0
		) {
			(userData['experienciaLaboral'] as Array<any>).forEach((e) => {
				let x: ExperienciaLaboralModel = {
					id: setValueOrDefault(e['id'], 'number'),
					cargo: setValueOrDefault(e['cargo'], 'string'),
					cargoEquivalente: setValueOrDefault(e['cargoEquivalente'], 'string'),
					cuaPersonasTrabajan: setValueOrDefault(e['cuaPersonasTrabajan'], 'string'),
					fechaIngresso: setValueOrDefault(e['fechaIngresso'], 'string'),
					fechaRetiro: setValueOrDefault(e['fechaRetiro'], 'string'),
					funcionesLogros: setValueOrDefault(e['funcionesLogros'], 'string'),
					nombreEmpresa: setValueOrDefault(e['nombreEmpresa'], 'string'),
					productoServicio: setValueOrDefault(e['productoServicio'], 'string'),
					sector: setValueOrDefault(e['sector'], 'string'),
					telefonoEmpresa: setValueOrDefault(e['telefonoEmpresa'], 'string'),
					tpExperienciaLaboral: setValueOrDefault(e['tpExperienciaLaboral'], 'string'),
					trabajandoMismoTrabajo: setValueOrDefault(e['trabajandoMismoTrabajo'], 'boolean'),
					paisEmpresa: setValueOrDefault(e['paisEmpresa'], 'string'),
				};
				expLaboralArr.push(x);
			});
		}
		return expLaboralArr;
	};

	const createObjectUserComplemento = (): UserComplementoModel[] => {
		let userCompArr: UserComplementoModel[] = [];
		let userComp: UserComplementoModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			primer_nome: setValueOrDefault(userData['primer_nombre'], 'string'),
			segundo_nome: setValueOrDefault(userData['segundo_nombre'], 'string'),
			primer_apelido: setValueOrDefault(userData['primer_apelido'], 'string'),
			segundo_apelido: setValueOrDefault(userData['segundo_apelido'], 'string'),
			tipo_doc: setValueOrDefault(userData['tipo_de_documento'], 'string'),
			numero_doc: setValueOrDefault(userData['numero_documento'], 'string'),
			fecha_nascimiento: setValueOrDefault(userData['fecha_nascimiento'], 'string'),
			celular: setValueOrDefault(userData['telefono_contacto'], 'number').toString(),
			genero: setValueOrDefault(userData['sexoFinal'], 'string')
		};
		userCompArr.push(userComp);
		return userCompArr;
	};

	const createObjectToSubmit = (): ResumeModel => {
		let resume: ResumeModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			step: setValueOrDefault(step, 'string'),
			estado_civil: setValueOrDefault(userData['estado_civil'], 'number'),
			numero_doc_adicional: setValueOrDefault(userData['numero_doc_adicional'], 'string'),
			tipo_doc_adicional: setValueOrDefault(userData['tipo_doc_adicional'], 'string'),
			sexo: setValueOrDefault(userData['sexoFinal'], 'string'),
			jefe_hogar: setValueOrDefault(userData['jefe_hogar'], 'string'),
			pais_nascimento: setValueOrDefault(userData['pais_nascimento'], 'string'),
			nacionalidad: setValueOrDefault(userData['nacionalidad'], 'string'),
			departamento_nacimiento: setValueOrDefault(userData['departamento_nacimiento'], 'string'),
			municipio_nacimiento: setValueOrDefault(userData['municipio_nacimiento'], 'string'),
			libreta_militar: setValueOrDefault(userData['libreta_militar'], 'string'),
			tipo_libreta: setValueOrDefault(userData['tipo_libreta'], 'string'),
			numero_libreta: setValueOrDefault(userData['numero_libreta'], 'string'),
			cuenta_sisben: setValueOrDefault(userData['cuenta_sisben'], 'string'),
            tipo_sisben: setValueOrDefault(userData['tipo_sisben'], 'string'),
			cuidador: setValueOrDefault(userData['cuidador'], 'string'),
            tipo_cuidador: setValueOrDefault(userData['tipo_cuidador'], 'string'),
			reconoce_focalizada: setValueOrDefault(userData['reconoce_focalizada'], 'string'),
			correo_Electronico: setValueOrDefault(userData['correo_Electronico'], 'string'),
			// telefono_contacto: setValueOrDefault(userData['telefono_contacto'], 'string'),
			otro_telefono: setValueOrDefault(userData['otro_telefono'], 'string'),
			observaciones: setValueOrDefault(userData['observaciones'], 'string'),
			pais_residencia: setValueOrDefault(userData['pais_residencia'], 'string'),
			bairro_residencia: setValueOrDefault(userData['bairro_residencia'], 'string'),
			pertence: setValueOrDefault(userData['pertence'], 'string'),
			prestador_preferencia: setValueOrDefault(userData['prestador_preferencia'], 'string'),
			punta_atencion: setValueOrDefault(userData['punta_atencion'], 'string'),
			local: setValueOrDefault(userData['local'], 'string'),
			id_coordenada: setValueOrDefault(userData['id_coordenada'], 'string'),
			id_coordenada_02: setValueOrDefault(userData['id_coordenada_02'], 'string'),
			id_coordenada_03: setValueOrDefault(userData['id_coordenada_03'], 'string'),
			id_coordenada_04: setValueOrDefault(userData['id_coordenada_04'], 'string'),
			id_coordenada_05: setValueOrDefault(userData['id_coordenada_05'], 'string'),
			id_coordenada_06: setValueOrDefault(userData['id_coordenada_06'], 'string'),
			id_coordenada_07: setValueOrDefault(userData['id_coordenada_07'], 'string'),
			id_coordenada_08: setValueOrDefault(userData['id_coordenada_08'], 'string'),
			id_coordenada_09: setValueOrDefault(userData['id_coordenada_09'], 'string'),
			codigo_postal: setValueOrDefault(userData['codigo_postal'], 'string'),
			direccion_residencia: setValueOrDefault(userData['direccion_residencia'], 'string'),
			possibilidade_viajar: setValueOrDefault(userData['possibilidade_viajar'], 'string'),
			situacion_laboral_actual: setValueOrDefault(userData['situacion_laboral_actual'], 'string'),
			ultimo_salario: setValueOrDefault(userData['ultimo_salario'], 'string'),
			aspiracion_salarial: setValueOrDefault(userData['aspiracion_salarial'], 'string'),
			aspiracion_salarial_min: setValueOrDefault(userData['aspiracion_salarial_min'], 'string'),
			Interes_ofertas_teletrabajo: setValueOrDefault(userData['Interes_ofertas_teletrabajo'],'string'),
			posibilidad_trasladarse: setValueOrDefault(userData['posibilidad_trasladarse'], 'string'),
			conduces_carro: setValueOrDefault(userData['conduces_carro'], 'string'),
			categoria_licencia_carro: setValueOrDefault(userData['categoria_licencia_carro'], 'string'),
			conduces_moto: setValueOrDefault(userData['conduces_moto'], 'string'),
			categoria_licencia_moto: setValueOrDefault(userData['categoria_licencia_moto'], 'string'),
			Prop_medio_transporte: setValueOrDefault(userData['Prop_medio_transporte'], 'string'),
			nivel_educativo_max: setValueOrDefault(userData['nivel_educativo_max'], 'string'),
			perfil_laboral: setValueOrDefault(userData['perfil_laboral'], 'string'),

			grupo_etnico: setValueOrDefault(userData['grupo_etnico'], 'string'),
			grupo_indigena: setValueOrDefault(userData['grupo_indigena'], 'string'),
            tipo_poblacion: setValueOrDefault(userData['tipo_poblacion'], 'string'),
			userComplemento: createObjectUserComplemento(),
			complementoDirecao: createObjectDirecao(),
			listPuestos: createObjectPuestos(),
			nivelEducativo: createObjectNivelEducativo(),
			capacitacionCertificacion: createObjectCapacitacionCertificacion(),
			listIdioma: createObjectIdioma(),
			listHerramientas: createObjectHerramienta(),
			experienciaLaboral: createObjectExpLaboral(),
		};

		return resume;
	};

	const handleClose = () => {
		setOpen(false);
	};

	const msgProfile = (value: string) => {
		if (value === undefined || value === '') return `Campo requerido\n\n0/1200`;

		if (value.length < 300)
			return `¡El campo debe tener al menos 300 caracteres!\n\n${value.length}/1200`;

		return `${value.length}/1200`;
	};

	const isValidProfile = (value: string) => {
		if (value === undefined || value === '') return false;

		if (value.length < 300) return false;

		return true;
	};
	const handleBlur = (field: any) => {
		setTouchedFields([...touchedFields, field]);
	}

	useEffect(() => {
		fetchDataNivelesEducativos();
	}, []);

	/**
	 * Realiza llamado a consulta de niveles educativos
	 */
	async function fetchDataNivelesEducativos() {
		try {
			const response = await getNivelesEducativos();
			if (response && response.data) {
				setListNivelEducativo(response.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	}

	return (
		<>
			{loadingParams
				? <LinearLoadingSpinner isLoading={loadingParams} />
				: <>
					<Typography color='#2A2A2A' variant='h6' mb={3} fontWeight='bold'>
						Datos de perfil laboral
					</Typography>
					<Grid container direction='row' justifyContent='left' width={'100%'} item>

						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('situacion_laboral_actual') && !isValidFieldRequired(userData['situacion_laboral_actual'])}
							>
								<InputLabel required id='situacion_laboral_actual'>
									Situación laboral actual
								</InputLabel>
								<Select
									required
									labelId='situacion_laboral_actual'
									id='situacion_laboral_actual'
									label='Situación laboral actual'
									value={userData['situacion_laboral_actual']}
									onBlur={() => handleBlur('situacion_laboral_actual')}
									onChange={(e) =>
										setUserData({ ...userData, situacion_laboral_actual: e.target.value })
									}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listSituacionLaboral.map(({ code, value }) => (
										<MenuItem key={code} value={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('situacion_laboral_actual') && !isValidFieldRequired(userData['situacion_laboral_actual']) && (
									<FormHelperText>
										{!isValidFieldRequired(userData['situacion_laboral_actual'])
											? 'Campo requerido'
											: ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<OverlayTrigger
								placement='top'
								delay={{ show: 250, hide: 400 }}
								overlay={(props) => (
									<Tooltip id='button-tooltip' {...props}>
										Defina si puede viajar por trabajo.
									</Tooltip>
								)}
							>
								<FormControl
									sx={{ minWidth: '100%' }}
									size='medium'
									error={touchedFields.includes('possibilidade_viajar') && !isValidFieldRequired(userData['possibilidade_viajar'])}
								>
									<InputLabel required id='possibilidade_viajar'>
										<span>
											Posibilidad de viajar
											<span
												className='bi bi-question-circle-fill'
												style={{ color: '#2266cc', marginLeft: '5px' }}
											></span>
										</span>
									</InputLabel>
									<Select
										required
										labelId='possibilidade_viajar'
										id='possibilidade_viajar'
										label='Posibilidad de viajar'
										value={userData['possibilidade_viajar']}
										onBlur={() => handleBlur('possibilidade_viajar')}
										onChange={(e) => setUserData({ ...userData, possibilidade_viajar: e.target.value })}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										<MenuItem value='0'>NO</MenuItem>
										<MenuItem value='1'>SI</MenuItem>
									</Select>
									{touchedFields.includes('possibilidade_viajar') && !isValidFieldRequired(userData['possibilidade_viajar']) && (
										<FormHelperText>
											{!isValidFieldRequired(userData['possibilidade_viajar']) ? 'Campo requerido' : ''}
										</FormHelperText>
									)}
								</FormControl>
							</OverlayTrigger>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('nivel_educativo_max') && !isValidFieldRequired(userData['nivel_educativo_max'])}
							>
								<InputLabel required id='nivel_educativo_max'>
									<span>
										{' '}
										¿Cuál es el nivel máximo de educación culminado con éxito?
									</span>
								</InputLabel>
								<Select
									required
									labelId='nivel_educativo_max'
									id='nivel_educativo_max'
									label='¿Cuál es el nivel máximo de educación culminado con éxito?'
									value={userData['nivel_educativo_max']}
									onBlur={() => handleBlur('nivel_educativo_max')}
									onChange={(e) => setUserData({ ...userData, nivel_educativo_max: e.target.value })}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listNivelEducativo.map(({ id, sigla_nivel_educativo, desc_nivel_educativo }) => (
										<MenuItem key={id} value={sigla_nivel_educativo}>
											{desc_nivel_educativo}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('nivel_educativo_max') && !isValidFieldRequired(userData['nivel_educativo_max']) && (
									<FormHelperText>
										{!isValidFieldRequired(userData['nivel_educativo_max']) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={12} md={3} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('interes_ofertas_teletrabajo') && !isValidFieldRequired(userData['Interes_ofertas_teletrabajo'])}
							>
								<InputLabel required id='Interes_ofertas_teletrabajo'>
									Interés en ofertas de teletrabajo
								</InputLabel>
								<Select
									required
									labelId='Interes_ofertas_teletrabajo'
									id='Interes_ofertas_teletrabajo'
									label='Interés en ofertas de teletrabajo'
									value={userData['Interes_ofertas_teletrabajo']}
									onBlur={() => handleBlur('interes_ofertas_teletrabajo')}
									onChange={(e) =>
										setUserData({ ...userData, Interes_ofertas_teletrabajo: e.target.value })
									}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listSiNo.map(({ code, value }) => (
										<MenuItem key={code} value={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('interes_ofertas_teletrabajo') && !isValidFieldRequired(userData['Interes_ofertas_teletrabajo']) && (
									<FormHelperText>
										{!isValidFieldRequired(userData['Interes_ofertas_teletrabajo'])
											? 'Campo requerido'
											: ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={12} md={3} mb={5} pr={2} item>
							<OverlayTrigger
								placement='top'
								delay={{ show: 250, hide: 400 }}
								overlay={(props) => (
									<Tooltip id='button-tooltip' {...props}>
										Seleccione el punto de atención más accesible a su residencia.
									</Tooltip>
								)}
							>
								<FormControl
									sx={{ minWidth: '100%' }}
									size='medium'
									error={touchedFields.includes('posibilidad_trasladarse') && !isValidFieldRequired(userData['posibilidad_trasladarse'])}
								>
									<InputLabel required id='posibilidad_trasladarse'>
										<span>
											{' '}
											Posibilidad de trasladarse*
											<span
												className='bi bi-question-circle-fill'
												style={{ color: '#2266cc', marginLeft: '5px' }}
											></span>
										</span>
									</InputLabel>
									<Select
										required
										labelId='posibilidad_trasladarse'
										id='posibilidad_trasladarse'
										label='Posibilidad de trasladarse'
										value={userData['posibilidad_trasladarse']}
										onBlur={() => handleBlur('posibilidad_trasladarse')}
										onChange={(e) =>
											setUserData({ ...userData, posibilidad_trasladarse: e.target.value })
										}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										{listSiNo.map(({ code, value }) => (
											<MenuItem key={code} value={code}>
												{value}
											</MenuItem>
										))}
									</Select>
									{touchedFields.includes('posibilidad_trasladarse') && !isValidFieldRequired(userData['posibilidad_trasladarse']) && (
										<FormHelperText>
											{!isValidFieldRequired(userData['posibilidad_trasladarse'])
												? 'Campo requerido'
												: ''}
										</FormHelperText>
									)}
								</FormControl>
							</OverlayTrigger>
						</Grid>
						<Grid xs={12} md={3} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('conduces_carro') && !isValidFieldRequired(userData['conduces_carro'])}
							>
								<InputLabel required id='conduces_carro'>
									Conduce carro
								</InputLabel>
								<Select
									required
									labelId='conduces_carro'
									id='conduces_carro'
									label='Conduce carro'
									value={userData['conduces_carro']}
									onBlur={() => handleBlur('conduces_carro')}
									onChange={(e) => setUserData({ ...userData, conduces_carro: e.target.value })}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listSiNo.map(({ code, value }) => (
										<MenuItem key={code} value={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('conduces_carro') && !isValidFieldRequired(userData['conduces_carro']) && (
									<FormHelperText>
										{!isValidFieldRequired(userData['conduces_carro']) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						{userData['conduces_carro'] == CODIGO_SI && (
							<Grid xs={12} md={3} mb={5} pr={2} item>
								<FormControl
									sx={{ minWidth: '100%' }}
									size='medium'
									error={touchedFields.includes('categoria_licencia_carro') && !isValidFieldRequired(userData['categoria_licencia_carro'])}
								>
									<InputLabel required id='categoria_licencia_carro'>
										Categoría licencia para carro
									</InputLabel>
									<Select
										required
										labelId='categoria_licencia_carro'
										id='categoria_licencia_carro'
										label='Categoría licencia para carro'
										value={userData['categoria_licencia_carro']}
										onBlur={() => handleBlur('categoria_licencia_carro')}
										onChange={(e) =>
											setUserData({ ...userData, categoria_licencia_carro: e.target.value })
										}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										{licenceCategoryList.map(({ code, value }) => (
											<MenuItem key={code} value={code}>
												{value}
											</MenuItem>
										))}
									</Select>
									{touchedFields.includes('categoria_licencia_carro') && !isValidFieldRequired(userData['categoria_licencia_carro']) && (
										<FormHelperText>
											{!isValidFieldRequired(userData['categoria_licencia_carro'])
												? 'Campo requerido'
												: ''}
										</FormHelperText>
									)}
								</FormControl>
							</Grid>
						)}
						<Grid xs={12} md={3} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('conduces_moto') && !isValidFieldRequired(userData['conduces_moto'])}
							>
								<InputLabel required id='conduces_moto'>
									Conduce moto
								</InputLabel>
								<Select
									required
									labelId='conduces_moto'
									id='conduces_moto'
									label='Conduce moto'
									value={userData['conduces_moto']}
									onBlur={() => handleBlur('conduces_moto')}
									onChange={(e) => setUserData({ ...userData, conduces_moto: e.target.value })}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listSiNo.map(({ code, value }) => (
										<MenuItem key={code} value={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('conduces_moto') && !isValidFieldRequired(userData['conduces_moto']) && (
									<FormHelperText>
										{!isValidFieldRequired(userData['conduces_moto']) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						{userData['conduces_moto'] == CODIGO_SI && (
							<Grid xs={12} md={3} mb={5} pr={2} item>
								<FormControl
									sx={{ minWidth: '100%' }}
									size='medium'
									error={touchedFields.includes('categoria_licencia_moto') && !isValidFieldRequired(userData['categoria_licencia_moto'])}
								>
									<InputLabel required id='categoria_licencia_moto'>
										Categoría licencia para moto
									</InputLabel>
									<Select
										required
										labelId='categoria_licencia_moto'
										id='categoria_licencia_moto'
										label='Categoría licencia para moto'
										value={userData['categoria_licencia_moto']}
										onBlur={() => handleBlur('categoria_licencia_moto')}
										onChange={(e) =>
											setUserData({ ...userData, categoria_licencia_moto: e.target.value })
										}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										{licenceCategoryMotorList.map(({ code, value }) => (
											<MenuItem key={code} value={code}>
												{value}
											</MenuItem>
										))}
									</Select>
									{touchedFields.includes('categoria_licencia_moto') && !isValidFieldRequired(userData['categoria_licencia_moto']) && (
										<FormHelperText>
											{!isValidFieldRequired(userData['categoria_licencia_moto'])
												? 'Campo requerido'
												: ''}
										</FormHelperText>
									)}
								</FormControl>
							</Grid>
						)}
						<Grid xs={12} md={3} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('prop_medio_transporte') && !isValidFieldRequired(userData['Prop_medio_transporte'])}
							>
								<InputLabel required id='Prop_medio_transporte'>
									Propietario de su medio de transporte
								</InputLabel>
								<Select
									required
									labelId='Prop_medio_transporte'
									id='Prop_medio_transporte'
									label='Propietario de su medio de transporte'
									value={userData['Prop_medio_transporte']}
									onBlur={() => handleBlur('prop_medio_transporte')}
									onChange={(e) => setUserData({ ...userData, Prop_medio_transporte: e.target.value })}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listSiNo.map(({ code, value }) => (
										<MenuItem key={code} value={code}>
											{value}
										</MenuItem>
									))}
								</Select>
								{touchedFields.includes('prop_medio_transporte') && !isValidFieldRequired(userData['Prop_medio_transporte']) && (
									<FormHelperText>
										{!isValidFieldRequired(userData['Prop_medio_transporte']) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={12} mb={5} pr={2} item>
							<OverlayTrigger
								placement='top'
								delay={{ show: 250, hide: 400 }}
								overlay={(props) => (
									<Tooltip id='button-tooltip' {...props}>
										Defina brevemente los conocimientos, aptitudes, habilidades y destrezas que le
										caracterizan para el desempeño de un cargo, ocupación u oficio.
									</Tooltip>
								)}
							>
								<FormControl sx={{ minWidth: '100%' }}>
									<TextField
										fullWidth
										required
										id='perfil_laboral'
										name='perfil_laboral'
										label='Perfil laboral'
										value={userData['perfil_laboral']}
										onChange={(e) => setUserData({ ...userData, perfil_laboral: e.target.value })}
										multiline
										onBlur={() => handleBlur('perfil_laboral')}
										minRows={5}
										maxRows={10}
										placeholder='Describa su perfil laboral'
										inputProps={{ maxLength: 1200, minLength: 300 }}
										error={touchedFields.includes('perfil_laboral') && !isValidProfile(userData['perfil_laboral'])}
										helperText={touchedFields.includes('perfil_laboral') ? msgProfile(userData['perfil_laboral']) : `${userData['perfil_laboral']?.length}/100`}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<i className='bi bi-question-circle-fill' style={{ color: '#2266cc' }}></i>
												</InputAdornment>
											),
										}}
										FormHelperTextProps={{ style: { wordWrap: 'break-word' } }}
									/>
								</FormControl>
							</OverlayTrigger>
						</Grid>
						<Grid xs={12} md={3.5} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%', maxWidth: '100%' }}
								size='medium'
								error={touchedFields.includes('ultimo_salario') && !isValidFieldRequired(userData['ultimo_salario'])}
							>
								<InputLabel required id='ultimo_salario'>
									<span>
										{' '}
										¿Cuánto es tu salario actual o último salario?
									</span>
								</InputLabel>
								<Select
									required
									labelId='ultimo_salario'
									id='ultimo_salario'
									label='¿Cuánto es tu salario actual o último salario?'
									value={userData['ultimo_salario']}
									onBlur={() => handleBlur('ultimo_salario')}
									onChange={(e) => setUserData({ ...userData, ultimo_salario: e.target.value })}
									style={{ overflowWrap: 'break-word', wordBreak: 'break-all', width: '100%' }}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{listSalarioMensual.map(({ code, value, label }) => (
										(code !== '-1') ? (
											<MenuItem key={code} value={code}>
												{formatNumber(value)} - {label}
											</MenuItem>
										) : (
											<MenuItem disabled key={code} value={code}>
												{label}
											</MenuItem>
										)
									))}
								</Select>
								{touchedFields.includes('ultimo_salario') && !isValidFieldRequired(userData['ultimo_salario']) && (
									<FormHelperText>
										{!isValidFieldRequired(userData['ultimo_salario']) ? 'Campo requerido' : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%', maxWidth: '100%' }}
								size='medium'
								error={(touchedFields.includes('aspiracion_salarial') && !isValidFieldRequired(userData['aspiracion_salarial'])) || !salarioMinIsGreaterThanSalarioMax(userData['aspiracion_salarial_min'], userData['aspiracion_salarial'])}
							>
								<InputLabel required id='aspiracion_salarial'>
									<span>
										{' '}
										¿Cuál es su expectativa salarial en este momento?
										{/* <span
											className='bi bi-question-circle-fill'
											style={{ color: '#2266cc', marginLeft: '5px' }}
										></span> */}
									</span>
								</InputLabel>
								<Select
									required
									labelId='aspiracion_salarial'
									id='aspiracion_salarial'
									label='¿Cuál es su expectativa salarial en este momento?'
									value={userData['aspiracion_salarial']}
									onBlur={() => handleBlur('aspiracion_salarial')}
									onChange={(e) => setUserData({ ...userData, aspiracion_salarial: e.target.value })}
								>
									{/*To Do Mejorar proceso para después */}
									<MenuItem value=''>Seleccione...</MenuItem>
									{listSalarioMensual.map(({ code, value, label }, i) => (
										(code !== '0' && code !== '-1') && <MenuItem value={code} key={i}>
											{formatNumber(value)} - {label}
										</MenuItem>
									))}

									{listSalarioMensual.map(({ code, label }, i) => (
										code === '-1' && <MenuItem disabled value={code} key={i}>
											{label}
										</MenuItem>
									))}
								</Select>
								{(touchedFields.includes('aspiracion_salarial') && !isValidFieldRequired(userData['aspiracion_salarial'])) || !salarioMinIsGreaterThanSalarioMax(userData['aspiracion_salarial_min'], userData['aspiracion_salarial']) && (
									<FormHelperText>
										{!isValidFieldRequired(userData['aspiracion_salarial']) ? 'Campo requerido' : helperSalarioMinIsGreaterThanSalarioMax(userData['aspiracion_salarial_min'], userData['aspiracion_salarial'], 'El salario mínimo aceptado no puede ser mayor a la expectativa salarial')}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={12} md={4.5} mb={5} pr={2} item>
							<OverlayTrigger
								placement='top'
								delay={{ show: 250, hide: 400 }}
								overlay={(props) => (
									<Tooltip id='button-tooltip' {...props}>
										¿Sí le ofrecieran trabajo en el lugar o empresa de sus sueños, por cuanto dinero mínimo estaría dispuesto a trabajar ahí o cambiar por ese trabajo?
										<br/>
										Recuerda que debe ser menor a la expectativa salarial
									</Tooltip>
								)}
							>
								<FormControl
									sx={{ minWidth: '100%', maxWidth: '100%' }}
									size='medium'
									error={(touchedFields.includes('aspiracion_salarial_min') && !isValidFieldRequired(userData['aspiracion_salarial_min'])) || !salarioMinIsGreaterThanSalarioMax(userData['aspiracion_salarial_min'], userData['aspiracion_salarial'])}
								>
									<InputLabel
										required
										id='aspiracion_salarial_min'
										style={{ overflowWrap: 'break-word' }}
									>
										<span>
											{' '}
											¿Cuál sería el salario mínimo aceptado en su trabajo soñado?
											<span
												className='bi bi-question-circle-fill'
												style={{ color: '#2266cc', marginLeft: '5px' }}
											></span>
										</span>
									</InputLabel>
									<Select
										required
										labelId='aspiracion_salarial_min'
										id='aspiracion_salarial_min'
										label='¿Cuál sería el salario mínimo aceptado en su trabajo soñado?'
										value={userData['aspiracion_salarial_min']}
										onBlur={() => handleBlur('aspiracion_salarial_min')}
										onChange={(e) => setUserData({ ...userData, aspiracion_salarial_min: e.target.value })}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										{listSalarioMensual.map(({ code, value, label }, i) => (
											(code !== '0' && code !== '-1') && <MenuItem value={code} key={i}>
												{formatNumber(value)} - {label}
											</MenuItem>
										))}

										{listSalarioMensual.map(({ code, label }, i) => (
											code === '-1' && <MenuItem disabled value={code} key={i}>
												{label}
											</MenuItem>
										))}
									</Select>
									{(touchedFields.includes('aspiracion_salarial_min') && !isValidFieldRequired(userData['aspiracion_salarial_min'])) || !salarioMinIsGreaterThanSalarioMax(userData['aspiracion_salarial_min'], userData['aspiracion_salarial']) && (
										<FormHelperText>
											{!isValidFieldRequired(userData['aspiracion_salarial_min']) ? 'Campo requerido' : helperSalarioMinIsGreaterThanSalarioMax(userData['aspiracion_salarial_min'], userData['aspiracion_salarial'], 'El salario mínimo aceptado no puede ser mayor a la expectativa salarial')}
										</FormHelperText>
									)}
								</FormControl>
							</OverlayTrigger>
						</Grid>
					</Grid>
					<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
							onClick={() => setStep(2)}
						>
							Atrás
						</Button>
						{/*<Box sx={{ flex: '1 1 auto' }} />*/}
						<Button
							variant='contained'
							sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
							onClick={handleSubmit}
						>
							Siguiente
						</Button>
					</Box>
					<Snackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={open}
						onClose={handleClose}
					>
						<Alert
							onClose={handleClose}
							variant={'filled'}
							severity={alert ? 'success' : 'error'}
							sx={{ whiteSpace: 'pre-line' }}
						>
							{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
							{alertMsg}
						</Alert>
					</Snackbar>
				</>
			}
		</>
	);
};
